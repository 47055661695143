import { webApi } from '@lib/api'

// list all sites
export const listSite = params => webApi({ auth: true }).get('/v1/site', params)
// create site
export const createSite = data =>
  webApi({ auth: true }).post('/v1/site', data, {
    formData: true,
  })

// detil site
export const detail = id => webApi({ auth: true }).get(`/v1/site/${id}`)

// sites overview
export const overview = (id, query = {}) => webApi({ auth: true }).post(`/v1/site/${id}/overview`, query)
// listr of trackings
export const tracking = (id, filters) => webApi({ auth: true }).get(`/v1/site/${id}/tracking?${filters}`)
// dispatch tracker
export const trackingSync = (siteId, id) => webApi({ auth: true }).post(`/v1/site/${siteId}/tracking/${id}/resync`)
// import tracker
export const trackingImport = (siteId, data) =>
  webApi({ auth: true }).post(`/v1/site/${siteId}/tracking/import/json`, data)
// create tracker
export const createTracking = (id, data) => webApi({ auth: true }).post(`/v1/site/${id}/tracking`, data)
// tracker detail
export const trackingDetail = (id, trackingId, params) =>
  webApi({ auth: true }).get(`/v1/site/${id}/tracking/${trackingId}`, params)
// manual sync site
export const siteSync = id => webApi({ auth: true }).post(`/v1/site/${id}/resync`)
// tracker detail
export const trackingDetailSummary = (id, trackingId, params) =>
  webApi({ auth: true }).get(`/v1/site/${id}/tracking/${trackingId}/summary`, params)
// list filter
export const listFilters = () => webApi({ auth: true }).get('/v1/filters/all')

// sync tracker
export const syncTracker = (siteId, trackerId) =>
  webApi({ auth: true }).post(`/v1/site/${siteId}/tracking/${trackerId}/resync`)
// delete tracker
export const deleteTracker = (siteId, trackerId) =>
  webApi({ auth: true }).delete(`/v1/site/${siteId}/tracking/${trackerId}`)
export const bulkDeleteTracker = (siteId, trackerId) =>
  webApi({ auth: true }).post(`/v1/site/${siteId}/tracking/all`, {
    id: trackerId,
  })

// get available sites
export const getAvailableSites = credentialId => webApi({ auth: true }).get(`/v1/credential/${credentialId}/sites`)
// get query counrter by page
export const getQueryMentioned = (siteId, page, query) =>
  webApi({ auth: true }).post(`/v1/site/${siteId}/mentioned/find`, {
    page,
    query,
  })
// reset
export const resetQueryMentioned = (siteId, page, query) =>
  webApi({ auth: true }).delete(`/v1/site/${siteId}/mentioned/reset`, {
    page,
    query,
  })

// get mentioned db
export const getMentionedPage = siteId => webApi({ auth: true }).get(`/v1/site/${siteId}/mentioned`)
export const getMentionedMeta = siteId => webApi({ auth: true }).get(`/v1/site/${siteId}/mentioned/meta`)

export const getAllMentioned = (siteId, page, search, curentPage, limit, filter) =>
  webApi({ auth: true }).get(`/v1/site/${siteId}/mentioned/all`, {
    page,
    search,
    current_page: curentPage,
    limit,
    ...filter,
  })
export const getMentionedPageQuery = (siteId, pageId) =>
  webApi({ auth: true }).get(`/v1/site/${siteId}/mentioned/${pageId}`)
export const getTrackingTag = siteId => webApi({ auth: true }).get(`/v1/site/${siteId}/tracking/tags`)
export const getTrackingSummary = (siteId, data) =>
  webApi({ auth: true }).get(`/v1/site/${siteId}/tracking/summary?${data}`)

// logs
export const createChangelog = (siteId, data) => webApi({ auth: true }).post(`/v1/site/${siteId}/changelog`, data)
export const getChangelog = (siteId, params) => webApi({ auth: true }).get(`/v1/site/${siteId}/changelog`, params)
export const updateChangelog = (siteId, changelogId, data) =>
  webApi({ auth: true }).post(`/v1/site/${siteId}/changelog/${changelogId}`, data)
export const deleteChangelog = (siteId, changelogId) =>
  webApi({ auth: true }).delete(`/v1/site/${siteId}/changelog/${changelogId}`)

//meta data
export const getMeta = siteId => webApi({ auth: true }).get(`/v1/site/${siteId}/meta`)
// sitemap

// list
export const getSitemapMonitor = siteId => webApi({ auth: true }).get(`/v1/site/${siteId}/sitemap-monitoring`)
// create
export const createSitemapMonitor = (siteId, data) =>
  webApi({ auth: true }).post(`/v1/site/${siteId}/sitemap-monitoring`, data)
// detail
export const detailSitemapMonitor = (siteId, sitemapId) =>
  webApi({ auth: true }).get(`/v1/site/${siteId}/sitemap-monitoring/${sitemapId}`)
// delete
export const deleteSitemapMonitor = (siteId, sitemapId) =>
  webApi({ auth: true }).delete(`/v1/site/${siteId}/sitemap-monitoring/${sitemapId}`)
// update
export const updateSitemapMonitor = (siteId, sitemapId, data) =>
  webApi({ auth: true }).post(`/v1/site/${siteId}/sitemap-monitoring/${sitemapId}`, data)
// get pages
export const getSitemapMonitorPage = (siteId, sitemapId) =>
  webApi({ auth: true }).get(`/v1/site/${siteId}/sitemap-monitoring/${sitemapId}/pages`)
// get page detail
export const getSitemapMonitorPageDetail = (siteId, sitemapId, sitemapPageId) =>
  webApi({ auth: true }).get(`/v1/site/${siteId}/sitemap-monitoring/${sitemapId}/${sitemapPageId}`)
// get pages history
export const getlSitemapMonitorPageHistory = (siteId, sitemapId, sitemapPageId) =>
  webApi({ auth: true }).get(`/v1/site/${siteId}/sitemap-monitoring/${sitemapId}/${sitemapPageId}/history`)
