export const PROFILE = {
  GET_PROFILE: 'PROFILE_GET_PROFILE',
  GET_PROFILE_SUCCESS: 'PROFILE_GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'PROFILE_GET_PROFILE_FAILURE',

  GET_PROFILE_SUBSCRIPTION: 'PROFILE_GET_PROFILE_SUBSCRIPTION',
  GET_PROFILE_SUBSCRIPTION_SUCCESS: 'PROFILE_GET_PROFILE_SUBSCRIPTION_SUCCESS',
  GET_PROFILE_SUBSCRIPTION_FAILURE: 'PROFILE_GET_PROFILE_SUBSCRIPTION_FAILURE',

  GET_PROFILE_SUBSCRIPTION_STATUS: 'GET_PROFILE_SUBSCRIPTION_STATUS',
  GET_PROFILE_SUBSCRIPTION_STATUS_SUCCESS: 'GET_PROFILE_SUBSCRIPTION_STATUS_SUCCESS',
  GET_PROFILE_SUBSCRIPTION_STATUS_FAILURE: 'GET_PROFILE_SUBSCRIPTION_STATUS_FAILURE',

  UPDATE_PROFILE: 'PROFILE_UPDATE_PROFILE',
  UPDATE_PROFILE_SUCCESS: 'PROFILE_UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'PROFILE_UPDATE_PROFILE_FAILURE',
}
