import { SOCKET } from '@constant/sockets'
import Cookie from '@lib/cookie'
import { eventChannel } from 'redux-saga'
import { call, fork, select, delay, take, put } from 'redux-saga/effects'
import { io } from 'socket.io-client'
import { currentPath, getRoute } from '@hook/router'
import router from 'next/router'
import { getSiteDetail, getSiteTracking } from '@action/site'
import { toast } from 'react-toastify'
import ReportAlert from '@component/pages/site/reports/report/alert'
import { getReport } from '@action/report'

function* socketConnection() {
  const authCookie = Cookie.get('--app-auth-token')
  const socketUrl = new URL(process.env.apiBaseUrl).origin
  const connection = io(socketUrl, {
    auth: {
      Authorization: `Bearer ${authCookie}`,
    },
  })

  return connection
}

function* clasifyRouterTracker(data) {
  const path = currentPath()
  if (path?.name == 'site.tracking.index') {
    yield put(getSiteTracking(data.siteId))
  } else if (path?.name == 'site.tracking.detail') {
    // console.log('demo')
  }
}

function* clasifyRouterSite() {
  const path = currentPath()
  const siteId = router?.router?.query?.id
  if (path?.name == 'site.tracking.index' || path?.name == 'site.detail' || path?.name == 'dashboard.home') {
    yield put(getSiteDetail(siteId))
  }
}

function* clasifyReport(data) {
  yield put(getReport(data.site_id))
  let wording = ''
  switch (data.type) {
    case 'TOP_PERFORMING_KEYWORD':
      wording = 'Top performing report has been generated!, click this to check it'
      break
    case 'CHANGELOG':
      wording = 'Changelog report has been generated!, click this to check it'
      break
    case 'SEOTEST':
      wording = 'SEO test report has been generated!, click this to check it'
      break
    case 'ORGANIC_TRAFFICT':
      wording = 'Organic traffict report has been generated!, click this to check it'
      break
    case 'FULL_REPORT':
      wording = 'Application report has been generated!, click this to check it'
      break
    case 'KEYWORD_RANKING':
      wording = 'Keyword ranking report has been generated!, click this to check it'
      break
  }
  toast.success(<ReportAlert text={wording} siteId={data.site_id} downloadUrl={data.fileUrl} />)
}

function* socketEvent(io, evenLists, activeSiteId) {
  return eventChannel(emit => {
    io.on(SOCKET.commands.CONNECT, async () => {
      await io.emit(SOCKET.commands.JOIN, activeSiteId)
    })
    // subscribe event
    for (const evt of evenLists) {
      io.on(evt, emit)
    }
    // unsubscribe event
    return () => {
      for (const evt of evenLists) {
        io.off(evt, emit)
      }
    }
  })
}

function* socketActions() {
  const activeSiteId = router?.router?.state?.query?.id
  // const io = yield call(socketConnection)
  // const evenLists = [SOCKET.events.SITE.UPDATE, SOCKET.events.SITE.TRACKING.UPDATE, SOCKET.events.SITE.REPORT.SUCCESS]
  // const sagaChannel = yield call(socketEvent, io, evenLists, activeSiteId)

  // while (true) {
  //   try {
  //     const payload = yield take(sagaChannel)
  //     if (payload) {
  //       const { data, event } = payload
  //       // console.log('Backend Event :', event, data)
  //       switch (event) {
  //         case SOCKET.events.SITE.UPDATE:
  //           yield call(clasifyRouterSite, data)
  //           break
  //         case SOCKET.events.SITE.TRACKING.UPDATE:
  //           yield call(clasifyRouterTracker, data)
  //           break
  //         case SOCKET.events.SITE.REPORT.SUCCESS:
  //           console.log('PUSH REPORT', data.data)
  //           yield call(clasifyReport, data.data)
  //           break
  //       }
  //     }
  //   } catch (e) {
  //     // exception handling
  //     console.error(e)
  //   }
  // }
}

export default function* rootSaga() {
  if (typeof window != 'undefined') {
    yield fork(socketActions)
  }
}
