import { FORGOT, LOGIN, LOGOUT, SIGNUP } from '@constant/auth'

export const login = data => ({
  type: LOGIN.POST,
  data,
})

export const loginSuccess = payload => ({
  type: LOGIN.SUCCESS,
  payload,
})

export const loginFailure = payload => ({
  type: LOGIN.FAILURE,
  payload,
})

export const signup = data => ({
  type: SIGNUP.POST,
  data,
})

export const signupSuccess = payload => ({
  type: SIGNUP.SUCCESS,
  payload,
})

export const signupFailure = payload => ({
  type: SIGNUP.FAILURE,
  payload,
})

export const logout = () => ({
  type: LOGOUT.POST,
})

export const forgot = email => ({
  type: FORGOT.POST,
  email,
})

export const forgotSuccess = payload => ({
  type: FORGOT.POST_SUCCES,
  payload,
})

export const forgotFailure = payload => ({
  type: FORGOT.POST_FAILURE,
  payload,
})

export const forgotVerify = token => ({
  type: FORGOT.VERIFY,
  token,
})

export const forgotVerifySuccess = payload => ({
  type: FORGOT.VERIFY_SUCCESS,
  payload,
})

export const forgotVerifyFailure = payload => ({
  type: FORGOT.VERIFY_FAILURE,
  payload,
})

export const resetPassword = data => ({
  type: FORGOT.RESET_PASSWORD,
  data,
})

export const resetPasswordSuccess = payload => ({
  type: FORGOT.RESET_PASSWORD_SUCCESS,
  payload,
})

export const resetPasswordFailure = payload => ({
  type: FORGOT.RESET_PASSWORD_FAILURE,
  payload,
})
