import { webApi } from '@lib/api'

export const getReport = siteId => webApi({ auth: true }).get(`/v1/site/${siteId}/report`)

export const topPerformingKeyword = siteId => webApi({ auth: true }).post(`/v1/site/${siteId}/report/top-performing`)
export const changelog = siteId => webApi({ auth: true }).post(`/v1/site/${siteId}/report/changelog`)
export const seoTest = siteId => webApi({ auth: true }).post(`/v1/site/${siteId}/report/seo-test`)
export const organicTraffict = siteId => webApi({ auth: true }).post(`/v1/site/${siteId}/report/organic-traffict`)
export const keywordRanking = siteId => webApi({ auth: true }).post(`/v1/site/${siteId}/report/keyword-ranking`)
export const fullReport = siteId => webApi({ auth: true }).post(`/v1/site/${siteId}/report/full-report`)

export const getPageTracking = (siteId, search, page, limit, sort, filter) =>
  webApi({ auth: true }).get(`/v1/site/${siteId}/page-tracking`, {
    search,
    page,
    limit,
    sort,
    ...filter,
  })

export const getPageTrackingDetail = (siteId, pageTrackingId) =>
  webApi({ auth: true }).get(`/v1/site/${siteId}/page-tracking/${pageTrackingId}`)

export const getPageTrackingReport = (siteId, pageTrackingId) =>
  webApi({ auth: true }).get(`/v1/site/${siteId}/page-tracking/${pageTrackingId}/report`)

export const getPageTrackingReportDetail = (siteId, pageTrackingId, reportId) =>
  webApi({ auth: true }).get(`/v1/site/${siteId}/page-tracking/${pageTrackingId}/report/${reportId}/detail`)

export const getPageTrackingReportHistory = (siteId, pageTrackingId, reportId) =>
  webApi({ auth: true }).get(`/v1/site/${siteId}/page-tracking/${pageTrackingId}/report/${reportId}`)

export const createPageTracking = (siteId, data) =>
  webApi({ auth: true }).post(`/v1/site/${siteId}/page-tracking`, data)

export const updatePageTracking = (siteId, pageTrackingId, data) => {
  return webApi({ auth: true }).post(`/v1/site/${siteId}/page-tracking/${pageTrackingId}`, data)
}

export const getPageTrackingReportMore = (siteId, pageTrackingId, data) => {
  return webApi({ auth: true }).get(`/v1/site/${siteId}/page-tracking/${pageTrackingId}/reports`, data)
}

// Page tracking chart

export const getPageTrackingChartPublished = (siteId, data) => {
  return webApi({ auth: true }).get(`/v1/site/${siteId}/page-tracking/summary-published`, data)
}

export const getPageTrackingChartHistory = (siteId, data) => {
  return webApi({ auth: true }).get(`/v1/site/${siteId}/page-tracking/summary-overall`, data)
}
