import NProgress from 'nprogress'
import { useDispatch, useStore } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Router, { useRouter } from 'next/router'
import { wrapper } from '@service/store'
import Head from 'next/head'
import { ThemeProvider } from 'styled-components'
import 'styles/globals.css'
import GlobalStyle from '@style/global'
import { themes } from '@style/themes'
import { useEffect } from 'react'
import { init } from '@action/app'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'flatpickr/dist/themes/material_blue.css'
import { getMeta, getSiteDetail } from '@action/site'
import SubscriptionExpire from '@component/modals/subscription/expire'
import PopupWindow, { Modal } from '@component/utils/modal'
import { openPopup } from '@action/menu'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function App({ Component, pageProps: { ...pageProps } }) {
  const store = useStore(state => state)
  const Dispatch = useDispatch()
  const queryClient = new QueryClient()
  const { query, asPath } = useRouter()

  useEffect(() => {
    if (query.id) {
      Dispatch(getSiteDetail(query.id))
      Dispatch(getMeta(query.id))
    }
    Dispatch(init())
  }, [query?.id, asPath])

  return (
    <>
      <Head>
        <title>{process.env.appName} </title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="author" content="" />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content="" />
        <meta property="og:url" content={pageProps.currentUrl} />
        <meta property="og:title" content="" />
        {/* <meta property="og:image" content="/manifest/android-icon-192x192.png" /> */}
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:description" content="" />
      </Head>
      <GoogleOAuthProvider clientId={process.env.google.clientId}>
        <ThemeProvider theme={themes('light')}>
          <GlobalStyle />

          <PersistGate persistor={store.__persistor} loading={<>Loading</>}>
            <QueryClientProvider client={queryClient}>
              <Component {...pageProps} />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <PopupWindow name="subscription-expire">
                <Modal>
                  <SubscriptionExpire />
                </Modal>
              </PopupWindow>
            </QueryClientProvider>
          </PersistGate>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </>
  )
}

export default wrapper.withRedux(App)
