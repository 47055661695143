import { toast } from 'react-toastify'
import { call } from 'redux-saga/effects'

export default function* callApi(api, ...params) {
  const response = yield call(api, ...params)
  const { ok, status } = response
  if (ok) {
    return response
  }
  if (!window.blockedApiAccess) {
    window.blockedApiAccess = true
    if (status == 401 || status == 403) {
      toast.error(`You can't access this page, because you are not authorized to this page`, {
        // delay: 10000,
        progress: 0,
        // isLoading: true,
      })

      setTimeout(() => {
        window.location.href = '/'
      }, 2000)
    }
  }
  return response
}
