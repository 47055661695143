import persistStorage from 'redux-persist/lib/storage'
import localForage from 'localforage'
import { persistReducer } from 'redux-persist'

const IDB_NAME = 'search-console'

const indexedDBInstance =
  typeof window == 'undefined'
    ? persistStorage
    : localForage.createInstance({
        name: IDB_NAME,
      })

export const flushStorage = async () => {
  if (typeof window != 'undefined') {
    await indexedDBInstance.clear()
    await indexedDBInstance.dropInstance()
    await indexedDB.deleteDatabase(IDB_NAME)
  }
}

if (typeof window != 'undefined') {
  window.indexedDBInstance = indexedDBInstance
}

export default indexedDBInstance

export const createPersistable = (instance, key, config = {}) => {
  const persistData = {
    key: key,
    storage: indexedDBInstance,
    ...config,
  }

  return persistReducer(persistData, instance)
}
