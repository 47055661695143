import { webApi } from '@lib/api'

export const loginOauth = payload => webApi().post('/v1/auth/oauth', payload)
export const login = payload => webApi().post('/v1/auth/signin', payload)
export const signup = payload => webApi().post('/v1/auth/signup', payload)

export const logout = () => webApi({ auth: true }).delete('/v1/auth/signout')

export const forgot = email => webApi().post('/v1/auth/forgot', { email })
export const forgotVerify = token => webApi().get(`/v1/auth/forgot/verify`, { token })
export const forgotUpdatePassword = data => webApi().post(`/v1/auth/forgot/update`, data)
