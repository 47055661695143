import moment from 'moment/moment'

const initialState = {
  all: {
    status: false,
    paginate: {},
    data: [],
  },
  create: {
    status: false,
    message: null,
  },
  detail: {
    // [SITE_ID]: {
    //   status: false,
    // data: {},
    // }
  },
  overview: {},
  compareOverview: {},
  tracking: {
    status: false,
    paginate: {},
    data: [],
  },
  trackingDetail: {},
  trackingCreate: {
    status: false,
    mesage: null,
  },
  trackingSync: {
    status: false,
    mesage: null,
  },
  filters: {
    status: false,
    data: [],
  },
  filtersCreate: {
    status: false,
    data: {},
  },
  typeFilters: {
    status: false,
    data: {},
  },
  overviewFilter: {
    startDate: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  },
  availableSites: {
    data: [],
    status: null,
  },

  trackingSummary: {
    status: false,
    filter: {},
    data: {},
  },
  trackingQuery: {},
  trackingImport: {},
  compareQuery: {},
  seoTestDetail: {},
  seoTestSummary: {},
  seoTestHistory: {},
  seoTestList: {},
  seoTestCreate: {},
  seoTestUpdate: {},
  seoTestImport: {},
  // missing keyword
  missingKeywordPage: {},
  missingKeywordPageList: {},
  missingKeywordQuery: {},
  misingKeywordPageData: {},
  trackingTag: {},
  trackingOverview: {},
  mentionedInMeta: {},
  changelogCreate: {},
  changelog: {},
  changelogUpdate: {},
  changelogDelete: {},
  siteMeta: {},
  sitemapMonitoringList: {},
  sitemapMonitoringPage: {},
  sitemapMonitoringDetail: {},
  sitemapMonitoringPageDetail: {},
  sitemapMonitoringPageHistory: {},
  sitemapMonitoringCreate: {},
  sitemapMonitoringUpdate: {},
  sitemapMonitoringDelete: {},
  report: {},
  pageTracking: {},
  pageTrackingDetail: {},
  pageTrackingReport: {},
  pageTrackingReportMore: {},
  pageTrackingCreate: {},
  pageTrackingUpdate: {},
  pageTrackingDelete: {},
  pageTrackingReportHistory: {},
  pageTrackingSync: {},
  pageTrackingChartPublisable: {},
  pageTrackingChartHistory: {},
}

export default initialState
