import { PROFILE } from '@constant/profile'

export const getProfile = () => ({
  type: PROFILE.GET_PROFILE,
})

export const getProfileSuccess = payload => ({
  type: PROFILE.GET_PROFILE_SUCCESS,
  payload,
})

export const getProfileFailure = payload => ({
  type: PROFILE.GET_PROFILE_FAILURE,
  payload,
})

export const updateProfile = data => ({
  type: PROFILE.UPDATE_PROFILE,
  data,
})

export const updateProfileSuccess = payload => ({
  type: PROFILE.UPDATE_PROFILE_SUCCESS,
  payload,
})

export const updateProfileFailure = payload => ({
  type: PROFILE.UPDATE_PROFILE_FAILURE,
  payload,
})

export const getProfileSubscription = () => ({
  type: PROFILE.GET_PROFILE_SUBSCRIPTION,
})

export const getProfileSubscriptionSuccess = payload => ({
  type: PROFILE.GET_PROFILE_SUBSCRIPTION_SUCCESS,
  payload,
})

export const getProfileSubscriptionFailure = payload => ({
  type: PROFILE.GET_PROFILE_SUBSCRIPTION_FAILURE,
  payload,
})

export const getProfileSubscriptionStatus = () => ({
  type: PROFILE.GET_PROFILE_SUBSCRIPTION_STATUS,
})

export const getProfileSubscriptionStatusSuccess = payload => ({
  type: PROFILE.GET_PROFILE_SUBSCRIPTION_STATUS_SUCCESS,
  payload,
})

export const getProfileSubscriptionStatusFailure = payload => ({
  type: PROFILE.GET_PROFILE_SUBSCRIPTION_STATUS_FAILURE,
  payload,
})
