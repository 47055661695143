export const SITE = {
  LIST: 'SITE_LIST',
  LIST_SUCCESS: 'SITE_LIST_SUCCESS',
  LIST_FAILURE: 'SITE_LIST_FAILURE',

  DETAIL: 'SITE_DETAIL',
  DETAIL_SUCCESS: 'SITE_DETAIL_SUCCESS',
  DETAIL_FAILURE: 'SITE_DETAIL_FAILURE',

  CREATE_SITE: 'SITE_CREATE_SITE',
  CREATE_SITE_SUCCESS: 'SITE_CREATE_SITE_SUCCESS',
  CREATE_SITE_FAILURE: 'SITE_CREATE_SITE_FAILURE',

  SYNC: 'SITE_SYNC',
  SYNC_FAILURE: 'SITE_SYNC_FAILURE',
  SYNC_SUCCESS: 'SITE_SYNC_SUCCESS',

  OVERVIEW: 'SITE_OVERVIEW',
  OVERVIEW_PAGE: 'SITE_OVERVIEW_PAGE',
  OVERVIEW_SUCCESS: 'SITE_OVERVIEW_SUCCESS',
  OVERVIEW_FAILURE: 'SITE_OVERVIEW_FAILURE',

  OVERVIEW_COMPARE: 'SITE_OVERVIEW_COMPARE',
  OVERVIEW_COMPARE_NO_DEBOUNCE: 'SITE_OVERVIEW_COMPARE_NO_DEBOUNCE',
  OVERVIEW_COMPARE_SUCCESS: 'SITE_OVERVIEW_COMPARE_SUCCESS',
  OVERVIEW_COMPARE_FAILURE: 'SITE_OVERVIEW_COMPARE_FAILURE',

  TRACKING: 'SITE_TRACKING',
  TRACKING_SUCCESS: 'SITE_TRACKING_SUCCESS',
  TRACKING_FAILURE: 'SITE_TRACKING_FAILURE',

  TRACKING_DETAIL: 'SITE_TRACKING_DETAIL',
  TRACKING_DETAIL_SUCCESS: 'SITE_TRACKING_DETAIL_SUCCESS',
  TRACKING_DETAIL_FAILURE: 'SITE_TRACKING_DETAIL_FAILURE',

  TRACKING_SUMMARY: 'SITE_TRACKING_SUMMARY',
  TRACKING_SUMMARY_SUCCESS: 'SITE_TRACKING_SUMMARY_SUCCESS',
  TRACKING_SUMMARY_FAILURE: 'SITE_TRACKING_SUMMARY_FAILURE',

  TRACKING_CREATE: 'SITE_TRACKING_CREATE',
  TRACKING_CREATE_SUCCESS: 'SITE_TRACKING_CREATE_FAILURE',
  TRACKING_CREATE_FAILURE: 'SITE_TRACKING_CREATE_FAILURE',

  FILTER_LIST: 'SITE_FILTER_LIST',
  FILTER_LIST_SUCCESS: 'SITE_FILTER_LIST_SUCCESS',
  FILTER_LIST_FAILURE: 'SITE_FILTER_LIST_FAILURE',

  FILTER_CREATE: 'SITE_FILTER_CREATE',
  FILTER_CREATE_SUCCESS: 'SITE_FILTER_CREATE_SUCCESS',
  FILTER_CREATE_FAILURE: 'SITE_FILTER_CREATE_FAILURE',

  // overview filter
  SET_OVERVIEW_FILTER: 'SITE_OVERVIEW_SET_FILTER',

  // tracking sync
  TRACKING_SYNC: 'SITE_TRACKING_SYNC',
  TRACKING_SYNC_SUCCESS: 'SITE_TRACKING_SYNC_SUCCESS',
  TRACKING_SYNC_FAILURE: 'SITE_TRACKING_SYNC_FAILURE',

  // tracking delete
  TRACKING_DELETE: 'SITE_TRACKING_DELETE',
  TRACKING_DELETE_SUCCESS: 'SITE_TRACKING_DELETE_SUCCESS',
  TRACKING_DELETE_FAILURE: 'SITE_TRACKING_DELETE_FAILURE',

  // tracking import
  TRACKING_IMPORT: 'SITE_TRACKING_IMPORT',
  TRACKING_IMPORT_SUCCESS: 'SITE_TRACKING_IMPORT_SUCCESS',
  TRACKING_IMPORT_FAILURE: 'SITE_TRACKING_IMPORT_FAILURE',

  GET_AVAILABLE_SITE: 'SITE_GET_AVAILABLE_SITE',
  GET_AVAILABLE_SITE_SUCCESS: 'SITE_GET_AVAILABLE_SITE_SUCCESS',
  GET_AVAILABLE_SITE_FAILURE: 'SITE_GET_AVAILABLE_SITE_FAILURE',

  // set trackign summary
  // date
  SET_TRACKING_SUMMARY_DATE: 'SITE_SET_TRACKING_SUMMARY_DATE',
  // type
  SET_TRACKING_SUMMARY_TYPE: 'SITE_SET_TRACKING_SUMMARY_TYPE',

  // data type
  SET_TRACKING_DATA_TYPE: 'SITE_SET_TRACKING_DATA_TYPE',
  // queries
  SET_TRACKING_SUMMARY_QUERY: 'SITE_SET_TRACKING_SUMMARY_QUERY',

  GET_MISSING_KEYWORD_PAGE: 'SITE_GET_MISSING_KEYWORD_PAGE',
  GET_MISSING_KEYWORD_PAGE_SUCCESS: 'SITE_GET_MISSING_KEYWORD_PAGE_SUCCESS',
  GET_MISSING_KEYWORD_PAGE_FAILURE: 'SITE_GET_MISSING_KEYWORD_PAGE_FAILURE',

  GET_MISSING_KEYWORD_PAGE_LIST: 'SITE_GET_MISSING_KEYWORD_PAGE_LIST',
  GET_MISSING_KEYWORD_PAGE_LIST_SUCCESS: 'SITE_GET_MISSING_KEYWORD_PAGE_LIST_SUCCESS',
  GET_MISSING_KEYWORD_PAGE_LIST_FAILURE: 'SITE_GET_MISSING_KEYWORD_PAGE_LIST_FAILURE',

  GET_MENTIONED_IN_META: 'SITE:GET:MENTIONED:IN:META',
  GET_MENTIONED_IN_META_SUCCESS: 'SITE:GET:MENTIONED:IN:META:SUCCESS',
  GET_MENTIONED_IN_META_FAILURE: 'SITE:GET:MENTIONED:IN:META:FAILURE',

  //missing keyword
  GET_PAGE_QUERY: 'SITE_GET_PAGE_QUERY',
  GET_PAGE_QUERY_SUCCESS: 'SITE_GET_PAGE_QUERY_SUCCESS',
  GET_PAGE_QUERY_FAILURE: 'SITE_GET_PAGE_QUERY_FAILURE',

  // get missing keyword counter
  GET_QUERY_COUNTER: 'SITE_GET_QUERY_COUNTER',
  GET_QUERY_COUNTER_SUCCESS: 'SITE_GET_QUERY_COUNTER_SUCCESS',
  GET_QUERY_COUNTER_FAILURE: 'SITE_GET_QUERY_COUNTER_FAILURE',

  // reset query counter
  RESET_QUERY_COUNTER: 'SITE_RESET_QUERY_COUNTER',
  RESET_QUERY_COUNTER_SUCCESS: 'SITE_RESET_QUERY_COUNTER_SUCCESS',
  RESET_QUERY_COUNTER_FAILURE: 'SITE_RESET_QUERY_COUNTER_FAILURE',

  TRACKING_TAG_GET: 'SITE_TRACKING_TAG_GET',
  TRACKING_TAG_GET_SUCCESS: 'SITE_TRACKING_TAG_GET_SUCCESS',
  TRACKING_TAG_GET_FAILURE: 'SITE_TRACKING_TAG_GET_FAILURE',

  TRACKING_TAG_EXPORT: 'SITE_TRACKING_TAG_EXPORT',

  TRACKING_OVERVIEW_GET: 'SITE_TRACKING_OVERVIEW_GET',
  TRACKING_OVERVIEW_GET_SUCCESS: 'SITE_TRACKING_OVERVIEW_GET_SUCCESS',
  TRACKING_OVERVIEW_GET_FAILURE: 'SITE_TRACKING_OVERVIEW_GET_FAILURE',

  LOG_CREATE: 'SITE_LOG_CREATE',
  LOG_CREATE_SUCCESS: 'SITE_LOG_CREATE_SUCCESS',
  LOG_CREATE_FAILURE: 'SITE_LOG_CREATE_FAILURE',

  LOG_GET: 'SITE_LOG_GET',
  LOG_GET_SUCCESS: 'SITE_LOG_GET_SUCCESS',
  LOG_GET_FAILURE: 'SITE_LOG_GET_FAILURE',

  LOG_UPDATE: 'SITE_LOG_UPDATE',
  LOG_UPDATE_SUCCESS: 'SITE_LOG_UPDATE_SUCCESS',
  LOG_UPDATE_FAILURE: 'SITE_LOG_UPDATE_FAILURE',

  LOG_DELETE: 'SITE_LOG_DELETE',
  LOG_DELETE_SUCCESS: 'SITE_LOG_DELETE_SUCCESS',
  LOG_DELETE_FAILURE: 'SITE_LOG_UPDATE_FAILURE',

  META_GET: 'SITE_META_GET',
  META_GET_SUCCESS: 'SITE_META_GET_SUCCESS',
  META_GET_FAILURE: 'SITE_META_GET_FAILURE',

  SITEMAP_MONITOR_GET: 'SITE_SITEMAP_MONITOR_GET',
  SITEMAP_MONITOR_GET_SUCCESS: 'SITE_SITEMAP_MONITOR_GET_GET',
  SITEMAP_MONITOR_GET_FAILURE: 'SITE_SITEMAP_MONITOR_GET_FAILURE',

  SITEMAP_MONITOR_CREATE: 'SITE_SITEMAP_MONITOR_CREATE',
  SITEMAP_MONITOR_CREATE_SUCCESS: 'SITE_SITEMAP_MONITOR_CREATE_SUCCESS',
  SITEMAP_MONITOR_CREATE_FAILURE: 'SITE_SITEMAP_MONITOR_CREATE_FAILURE',

  SITEMAP_MONITOR_DETAIL: 'SITE_SITEMAP_MONITOR_DETAIL',
  SITEMAP_MONITOR_DETAIL_SUCCESS: 'SITE_SITEMAP_MONITOR_DETAIL_SUCCESS',
  SITEMAP_MONITOR_DETAIL_FAILURE: 'SITE_SITEMAP_MONITOR_DETAIL_FAILURE',

  SITEMAP_MONITOR_UPDATE: 'SITE_SITEMAP_MONITOR_UPDATE',
  SITEMAP_MONITOR_UPDATE_SUCCESS: 'SITE_SITEMAP_MONITOR_UPDATE_SUCCESS',
  SITEMAP_MONITOR_UPDATE_FAILURE: 'SITE_SITEMAP_MONITOR_UPDATE_FAILURE',

  SITEMAP_MONITOR_DELETE: 'SITE_SITEMAP_MONITOR_DELETE',
  SITEMAP_MONITOR_DELETE_SUCCESS: 'SITE_SITEMAP_MONITOR_DELETE_SUCCESS',
  SITEMAP_MONITOR_DELETE_FAILURE: 'SITE_SITEMAP_MONITOR_DELETE_FAILURE',

  SITEMAP_MONITOR_PAGE_GET: 'SITE_SITEMAP_MONITOR_PAGE_GET',
  SITEMAP_MONITOR_PAGE_GET_SUCCESS: 'SITE_SITEMAP_MONITOR_PAGE_GET_SUCCESS',
  SITEMAP_MONITOR_PAGE_GET_FAILURE: 'SITE_SITEMAP_MONITOR_PAGE_GET_FAILURE',

  SITEMAP_MONITOR_PAGE_DETAIL: 'SITE_SITEMAP_MONITOR_PAGE_DETAIL',
  SITEMAP_MONITOR_PAGE_DETAIL_SUCCESS: 'SITE_SITEMAP_MONITOR_PAGE_DETAIL_SUCCESS',
  SITEMAP_MONITOR_PAGE_DETAIL_FAILURE: 'SITE_SITEMAP_MONITOR_PAGE_DETAIL_FAILURE',

  SITEMAP_MONITOR_PAGE_HISTORY_GET: 'SITE_SITEMAP_MONITOR_PAGE_HISTORY_GET',
  SITEMAP_MONITOR_PAGE_HISTORY_GET_SUCCESS: 'SITE_SITEMAP_MONITOR_PAGE_HISTORY_GET_SUCCESS',
  SITEMAP_MONITOR_PAGE_HISTORY_GET_FAILURE: 'SITE_SITEMAP_MONITOR_PAGE_HISTORY_GET_FAILURE',

  PAGE_TRACKING_LIST: 'REPORT_PAGE_TRACKING_LIST',
  PAGE_TRACKING_LIST_SUCCESS: 'REPORT_PAGE_TRACKING_LIST_SUCCESS',
  PAGE_TRACKING_LIST_FAILURE: 'REPORT_PAGE_TRACKING_LIST_FAILURE',

  PAGE_TRACKING_DETAIL: 'REPORT_PAGE_TRACKING_DETAIL',
  PAGE_TRACKING_DETAIL_SUCCESS: 'REPORT_PAGE_TRACKING_DETAIL_SUCCESS',
  PAGE_TRACKING_DETAIL_FAILURE: 'REPORT_PAGE_TRACKING_DETAIL_FAILURE',

  PAGE_TRACKING_CREATE: 'REPORT_PAGE_TRACKING_CREATE',
  PAGE_TRACKING_CREATE_SUCCESS: 'REPORT_PAGE_TRACKING_CREATE_SUCCESS',
  PAGE_TRACKING_CREATE_FAILURE: 'REPORT_PAGE_TRACKING_CREATE_FAILURE',

  PAGE_TRACKING_UPDATE: 'REPORT_PAGE_TRACKING_UPDATE',
  PAGE_TRACKING_UPDATE_SUCCESS: 'REPORT_PAGE_TRACKING_UPDATE_SUCCESS',
  PAGE_TRACKING_UPDATE_FAILURE: 'REPORT_PAGE_TRACKING_UPDATE_FAILURE',

  PAGE_TRACKING_DELETE: 'REPORT_PAGE_TRACKING_DELETE',
  PAGE_TRACKING_DELETE_SUCCESS: 'REPORT_PAGE_TRACKING_DELETE_SUCCESS',
  PAGE_TRACKING_DELETE_FAILURE: 'REPORT_PAGE_TRACKING_DELETE_FAILURE',

  PAGE_TRACKING_MANUAL_SYNC: 'REPORT_PAGE_TRACKING_MANUAL_SYNC',
  PAGE_TRACKING_MANUAL_SYNC_SUCCESS: 'REPORT_PAGE_TRACKING_MANUAL_SYNC_SUCCESS',
  PAGE_TRACKING_MANUAL_SYNC_FAILURE: 'REPORT_PAGE_TRACKING_MANUAL_SYNC_FAILURE',

  PAGE_TRACKING_REPORT: 'REPORT_PAGE_TRACKING_REPORT',
  PAGE_TRACKING_REPORT_SUCCESS: 'REPORT_PAGE_TRACKING_REPORT_SUCCESS',
  PAGE_TRACKING_REPORT_FAILURE: 'REPORT_PAGE_TRACKING_REPORT_FAILURE',

  PAGE_TRACKING_REPORT_HISTORY: 'REPORT_PAGE_TRACKING_REPORT_HISTORY',
  PAGE_TRACKING_REPORT_HISTORY_SUCCESS: 'REPORT_PAGE_TRACKING_REPORT_HISTORY_SUCCESS',
  PAGE_TRACKING_REPORT_HISTORY_FAILURE: 'REPORT_PAGE_TRACKING_REPORT_HISTORY_FAILURE',

  PAGE_TRACKING_MORE_REPORT: 'REPORT_PAGE_TRACKING_MORE_REPORT',
  PAGE_TRACKING_MORE_REPORT_SUCCESS: 'REPORT_PAGE_TRACKING_MORE_SUCCESS',
  PAGE_TRACKING_MORE_REPORT_FAILURE: 'REPORT_PAGE_TRACKING_MORE_FAILURE',

  PAGE_TRACKING_CHART_PUBLISABLE: 'REPORT_PAGE_TRACKING_CHART_PUBLISABLE',
  PAGE_TRACKING_CHART_PUBLISABLE_SUCCESS: 'REPORT_PAGE_TRACKING_CHART_PUBLISABLE_SUCCESS',
  PAGE_TRACKING_CHART_PUBLISABLE_FAILURE: 'REPORT_PAGE_TRACKING_CHART_PUBLISABLE_FAILURE',

  PAGE_TRACKING_CHART_HISTORY: 'REPORT_PAGE_TRACKING_CHART_HISTORY',
  PAGE_TRACKING_CHART_HISTORY_SUCCESS: 'REPORT_PAGE_TRACKING_CHART_HISTORY_SUCCESS',
  PAGE_TRACKING_CHART_HISTORY_FAILURE: 'REPORT_PAGE_TRACKING_CHART_HISTORY_FAILURE',
}
