import { SEOTEST } from '@constant/seoTest'

//list
export const getTestList = (siteId, type = 'ALL', search = '', status = 'ALL') => ({
  type: SEOTEST.LIST,
  siteId,
  seoTestType: type,
  search,
  status,
})

export const getTestListSuccess = (siteId, payload) => ({
  type: SEOTEST.LIST_SUCCESS,
  payload,
  siteId,
})

export const getTestListFailure = (siteId, payload) => ({
  type: SEOTEST.LIST_FAILURE,
  payload,
  siteId,
})

//create

export const createTest = (data, testType, siteId) => ({
  type: SEOTEST.CREATE,
  testType,
  siteId,
  data,
})

export const createTestSuccess = (siteId, payload) => ({
  type: SEOTEST.CREATE_SUCCESS,
  siteId,
  payload,
})

export const createTestFailure = (siteId, payload) => ({
  type: SEOTEST.CREATE_FAILURE,
  siteId,
  payload,
})

//update
export const updateTest = (data, siteId, seoTestId, testType) => ({
  type: SEOTEST.UPDATE,
  siteId,
  data,
  seoTestId,
  testType,
})

export const updateTestSuccess = (seoTestId, payload) => ({
  type: SEOTEST.UPDATE_SUCCESS,
  seoTestId,
  payload,
})

export const updateTestFailure = (seoTestId, payload) => ({
  type: SEOTEST.UPDATE_FAILURE,
  seoTestId,
  payload,
})

// detail test

export const detailTest = (siteId, seoTestId) => ({
  type: SEOTEST.DETAIL_TEST,
  siteId,
  seoTestId,
})

export const detailTestSuccess = (seoTestId, payload) => ({
  type: SEOTEST.DETAIL_TEST_SUCCESS,
  seoTestId,
  payload,
})

export const detailTestFailure = (seoTestId, payload) => ({
  type: SEOTEST.DETAIL_TEST_FAILURE,
  seoTestId,
  payload,
})

// summary seo test
export const summaryTest = (siteId, seoTestId, week) => ({
  type: SEOTEST.DETAIL_SUMMARY_TEST,
  siteId,
  seoTestId,
  week,
})

export const summaryTestSuccess = (seoTestId, payload) => ({
  type: SEOTEST.DETAIL_SUMMARY_TEST_SUCCESS,
  seoTestId,
  payload,
})

export const summaryTestFailure = (seoTestId, payload) => ({
  type: SEOTEST.DETAIL_SUMMARY_TEST_FAILURE,
  seoTestId,
  payload,
})

// history of chart
export const historyTest = (siteId, seoTestId, week) => ({
  type: SEOTEST.DETAIL_HISTORY_TEST,
  siteId,
  week,
  seoTestId,
})

export const historyTestSuccess = (seoTestId, payload) => ({
  type: SEOTEST.DETAIL_HISTORY_TEST_SUCCESS,
  seoTestId,
  payload,
})

export const historyTestFailure = (seoTestId, payload) => ({
  type: SEOTEST.DETAIL_HISTORY_TEST_FAILURE,
  seoTestId,
  payload,
})

// rerun job / dispatch
export const dispatchTest = (siteId, seoTestId) => ({
  type: SEOTEST.DISPATCH_TEST,
  siteId,
  seoTestId,
})

export const dispatchTestSuccess = (seoTestId, payload) => ({
  type: SEOTEST.DISPATCH_TEST_SUCCESS,
  seoTestId,
  payload,
})

export const dispatchTestFailure = (seoTestId, payload) => ({
  type: SEOTEST.DISPATCH_TEST_FAILURE,
  seoTestId,
  payload,
})

// rerun job / dispatch
export const importTest = (siteId, data, callback) => ({
  type: SEOTEST.IMPORT_TEST,
  siteId,
  data,
  callback,
})

export const importTestSuccess = (siteId, payload) => ({
  type: SEOTEST.IMPORT_TEST_SUCCESS,
  siteId,
  payload,
})

export const importTestFailure = (siteId, payload) => ({
  type: SEOTEST.IMPORT_TEST_FAILURE,
  siteId,
  payload,
})

// Delete seo test
export const deleteTest = (siteId, seoTestId) => ({
  type: SEOTEST.DELETE_TEST,
  siteId,
  seoTestId,
})

export const deleteTestSuccess = (siteId, payload) => ({
  type: SEOTEST.DELETE_TEST_SUCCESS,
  siteId,
  payload,
})

export const deleteTestFailure = (siteId, payload) => ({
  type: SEOTEST.DELETE_TEST_FAILURE,
  siteId,
  payload,
})
