import styled, { css } from 'styled-components'
import Color from '@alchemyalcove/color-calculator'

const ButtonStyle = css`
  ${props =>
    !props.btnBlock &&
    `
    padding: 12px 30px;  
    `}
  ${props =>
    props.btnNormal &&
    `
    padding: 15px 24px;  
    `}
  ${props =>
    props.btnBlock &&
    `
    width: 100%;
    padding: 13px 15px; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    `}
  cursor: pointer;
  display: flex;
  text-align: center;
  ${props =>
    props.btnStyle &&
    `
    width: ${props.btnStyle == 'block' ? '100%' : props.btnWidth ? props.btnWidth : 'unset'};
    display: ${props.btnStyle};
  `};
  align-items: center;
  font-size: ${props =>
    props.btnFontSize
      ? props?.btnFontSize?.includes('px') || props?.btnFontSize?.includes('rem')
        ? props.btnFontSize
        : props.theme.sizes[props?.btnFontSize]
      : props.theme.sizes.md};
  font-weight: ${props => props.btnFontWeight};
  color: ${props =>
    props.btnColor
      ? props.btnColor.includes('#')
        ? props.btnColor
        : props.theme.colors[props.btnColor]
      : props.theme.colors.primary};
  background-color: ${props =>
    props.btnBackground
      ? props.btnBackground.includes('#')
        ? props.btnBackground
        : props.theme.colors[props.btnBackground]
      : props.theme.colors.primary};
  border-radius: 12px;
  border: 1px solid
    ${props =>
      props.btnBorder
        ? props.btnBorder.includes('#')
          ? props.btnBorder
          : props.theme.colors[props.btnBorder]
        : props.theme.colors.primary};
  transition: 0.3s ease;
  :hover {
    background-color: ${props =>
      props.btnBackground
        ? props.btnBackground.includes('#')
          ? new Color(props.btnBackground).darken(10).toHex()
          : props.theme.colors[props.btnBorder]
          ? new Color(props.theme.colors[props.btnBorder]).lighten(25).toHex()
          : new Color(props.theme.colors[props.btnBackground]).darken(10).toHex()
        : new Color(props.theme.colors.primary).darken(10).toHex()};
  }
  // disabled value
  ${props =>
    props.disabled &&
    `
    background-color: ${new Color(props.theme.colors[props.btnBackground] || props.theme.colors.primary)
      .lighten(19)
      .toHex()} !important;
  border: 1px solid
    ${new Color(props.theme.colors[props.btndBorder] || props.theme.colors.primary).lighten(19).toHex()} !important;
    `}
  ${props =>
    props.sm &&
    `
    font-size: .9rem;
    padding: 7px 15px !important;
    border-radius: 8px;
  `}
`

export const Button = styled.button`
  ${ButtonStyle}
`
export const LinkButton = styled.a`
  ${ButtonStyle}
`
export const LinkRef = styled.a`
  color: ${props => props.theme.colors.primary};
`
