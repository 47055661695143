import { COUNTRY } from '@constant/country'

export const getCountry = () => ({
  type: COUNTRY.GET,
})

export const getCountryFailure = payload => ({
  type: COUNTRY.GET_FAILURE,
  payload,
})

export const getCountrySuccess = payload => ({
  type: COUNTRY.GET_SUCCESS,
  payload,
})
