import { call, put, takeLatest } from 'redux-saga/effects'
import callApi from '@saga/api'
import { createCredential, deleteCredential, listCredential } from '@api/credential'
import { CREDENTIAL } from '@constant/credential'
import {
  createCredentialFailure,
  createCredentialSuccess,
  deleteCredentialFailure,
  deleteCredentialSuccess,
  setCredentialList,
} from '@action/credential'
import { toast } from 'react-toastify'

function* listCredentialSaga() {
  const { ok, data: response } = yield call(callApi, listCredential)
  if (ok) {
    yield put(setCredentialList(response))
  }
}

function* createCredentialSaga({ data, callback }) {
  const { ok, data: response } = yield call(callApi, createCredential, data)
  if (ok) {
    yield put(createCredentialSuccess(response))
    yield call(listCredentialSaga)
    toast.success('Credential updated')
    if (callback) {
      yield callback()
    }
  } else {
    toast.error('Failed to add or update credential')
    yield put(createCredentialFailure(response))
  }
}

function* deleteCredentialSaga({ credentialId, callback }) {
  const { ok, data: response } = yield call(callApi, deleteCredential, credentialId)
  if (ok) {
    yield put(deleteCredentialSuccess(response))
    yield call(listCredentialSaga)
    toast.success('Credential deleted')
    if (callback) {
      yield callback()
    }
  } else {
    toast.error('Failed to delete credential')
    yield put(deleteCredentialFailure(response))
  }
}

export default function* rootSaga() {
  yield takeLatest(CREDENTIAL.LIST, listCredentialSaga)
  yield takeLatest(CREDENTIAL.CREATE, createCredentialSaga)
  yield takeLatest(CREDENTIAL.DELETE, deleteCredentialSaga)
}
