import { call, debounce, put } from 'redux-saga/effects'
import callApi from '@saga/api'
import { listCountry } from '@api/country'
import { getCountryFailure, getCountrySuccess } from '@action/country'
import { COUNTRY } from '@constant/country'

function* listCountrySaga() {
  const { ok, data: response } = yield call(callApi, listCountry)
  if (ok) {
    yield put(getCountrySuccess(response))
  } else {
    yield put(getCountryFailure(response))
  }
}

export default function* rootSaga() {
  yield debounce(10, COUNTRY.GET, listCountrySaga)
}
