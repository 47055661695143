import { useSelector, useDispatch } from 'react-redux'
import { useRef, useEffect } from 'react'
import { openPopup, closePopup } from '@action/menu'
import { PopupModal, ModalOverlay, ModalParent } from '@style/components/modal'
import { useCallback } from 'react'
import { cloneElement } from 'react'
import 'react-quill/dist/quill.snow.css'
/**
 * Make popup will auto close
 * @params ctx {children, name}
 */

const PopupWindow = ({ children, name }) => {
  const popupState = useSelector(state => state.app?.popup)
  const popupRef = useRef()
  return <>{popupState?.[name] ? <div ref={popupRef}>{cloneElement(children, { name })}</div> : ''}</>
}
export const dispatchPopup = (name, dispatch, ...data) => {
  return dispatch(openPopup(name, ...data))
}

/**
 * Modals
 */

export const Modal = ({ children, name, alignItems, justifyContent, top, left }) => {
  const Dispatch = useDispatch()
  const popupParentRef = useRef()
  // close
  const close = useCallback(() => {
    Dispatch(closePopup(name))
  }, [name, Dispatch])

  // close by escape
  useEffect(() => {
    const handleEscape = e => {
      if (e.key == 'Escape') {
        Dispatch(closePopup(name))
      }
    }
    window.addEventListener('keydown', handleEscape)
    return () => {
      window.removeEventListener('keydown', handleEscape)
    }
  }, [name, Dispatch])

  return (
    <>
      <PopupModal alignItems={alignItems} justifyContent={justifyContent} top={top} left={left} className="popup-overlay" ref={popupParentRef}>
        <ModalOverlay onClick={e => Dispatch(closePopup(name))} />
        <ModalParent>{cloneElement(children, { close, name })}</ModalParent>
      </PopupModal>
    </>
  )
}

export default PopupWindow
