import Router from 'next/router'
import RouterPath from '@/routes'
/**
 * Making route simple
 * @param {*} param0
 * @param {*} route
 */

export const useRouter = ({ req, res }) => {
  return {
    push: (url, code = 302) => {
      if (req) {
        res.writeHead(code, {
          location: url,
        })
        return res.end()
      } else {
        return Router.push(url)
      }
    },
  }
}

/**
 * Matching route by path and namespace
 * @param {String} namePath
 */
const getMatchRoute = namePath => {
  return RouterPath.find(itm => (namePath.includes('.') ? itm.name === namePath : itm.path === namePath))
}

/**
 * Returning active router
 * @param {String} namePath
 * @param {Boolean} exact
 * @param {String} activeClass
 * @param {String} inActiveClass
 */
export const matchRoute = (namePath = '', exact = false, activeClass = 'active', inActiveClass = null) => {
  const currentRoute = Router.router.route
  const currentPath = Router.router.asPath
  const matchedRoute = getMatchRoute(namePath)
  if (!matchedRoute) {
    throw new Error(`No route with this name "${namePath}" found`)
  }
  return (exact ? currentRoute == matchedRoute.path : currentPath?.includes(matchedRoute?.path))
    ? activeClass
    : inActiveClass
}

/**
 * Get route url
 * @param {String} keyName
 * @param {Object} params
 */
export const getRoute = (keyName = '', params = {}, queries = {}) => {
  const matchedRoute = getMatchRoute(keyName)
  if (!matchedRoute) {
    throw new Error(`No route with this name "${keyName}" found`)
  }
  let routePath = matchedRoute?.path
  for (const key in params) {
    routePath = routePath.replaceAll(`[${key}]`, params[key])
  }
  let loopIndex = 0
  for (const key in queries) {
    routePath = routePath.concat(`${loopIndex == 0 ? '?' : '&'}${key}=`, queries[key])
    loopIndex++
  }
  return encodeURI(routePath)
}

/**
 * Get current route and namespace
 */
export const currentPath = () => {
  const currentPath = Router.router.route
  return getMatchRoute(currentPath)
}
