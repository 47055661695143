/* eslint-disable import/no-anonymous-default-export */
/**
 * Route namespaces
 */
export default [
  // auth login
  {
    name: 'auth.login',
    path: '/auth/login',
  },

  // auth signup
  {
    name: 'auth.signup',
    path: '/auth/signup',
  },

  // auth forgot
  {
    name: 'auth.forgot',
    path: '/auth/forgot',
  },
  {
    name: 'auth.forgot.verify',
    path: '/auth/forgot/[token]',
  },

  // settings
  {
    name: 'settings.index',
    path: '/settings',
  },
  {
    name: 'settings.profile',
    path: '/settings/profile',
  },
  {
    name: 'settings.billing',
    path: '/settings/billing',
  },
  {
    name: 'settings.credential',
    path: '/settings/credential',
  },

  // dashbpard
  {
    name: 'dashboard.home',
    path: '/',
  },
  // sites
  {
    name: 'site.all',
    path: '/',
  },
  {
    name: 'site.detail',
    path: '/site/[id]',
  },
  {
    name: 'site.performance',
    path: '/site/[id]/performance',
  },
  {
    name: 'site.filter',
    path: '/site/[id]/filter',
  },
  {
    name: 'site.filter.create',
    path: '/site/[id]/filter/create',
  },
  {
    name: 'site.filter.detail',
    path: '/[id]/[filterId]',
  },
  {
    name: 'site.edit',
    path: '/site/[id]/edit',
  },

  // keyword monitoring
  {
    name: 'site.tracking.index',
    path: '/site/[id]/tracking',
  },
  {
    name: 'site.tracking.tags',
    path: '/site/[id]/tracking/tags',
  },
  {
    name: 'site.tracking.detail',
    path: '/site/[id]/tracking/[trackerId]',
  },
  // SEO Test
  { name: 'site.seo.test', path: '/site/[id]/seo-test' },
  { name: 'site.seo.test.result', path: '/site/[id]/seo-test/result/[testType]/[seoTestId]' },
  { name: 'site.seo.test.result.raw', path: '/site/[id]/seo-test/result/[testType]/[seoTestId]/raw' },
  { name: 'site.seo.test.create', path: '/site/[id]/seo-test/create' },
  { name: 'site.seo.test.edit', path: '/site/[id]/seo-test/result/[testType]/[seoTestId]/edit' },
  { name: 'site.seo.test.clone', path: '/site/[id]/seo-test/result/[testType]/[seoTestId]/clone' },
  { name: 'site.seo.test.advanced', path: '/site/[id]/seo-test/advanced-test' },
  { name: 'site.seo.test.seo-title', path: '/site/[id]/seo-test/seo-title-test' },
  { name: 'site.seo.test.meta-desc', path: '/site/[id]/seo-test/seo-meta-desc' },
  { name: 'site.seo.test.schema-implementation', path: '/site/[id]/seo-test/schema-implementation' },
  { name: 'site.seo.test.redirect-test', path: '/site/[id]/seo-test/redirect-test' },
  { name: 'site.seo.test.backlink-test', path: '/site/[id]/seo-test/backlink-test' },
  { name: 'site.seo.test.content-change', path: '/site/[id]/seo-test/content-change' },
  { name: 'site.seo.test.upload.single', path: '/site/[id]/seo-test/upload-single-test' },

  // missing keyword
  {
    name: 'site.mentioned-keyword',
    path: '/site/[id]/reports/mentioned-keyword',
  },
  {
    name: 'site.mentioned-keyword.meta',
    path: '/site/[id]/reports/mentioned-keyword/mentioned-in-meta',
  },
  {
    name: 'site.mentioned-keyword.detail',
    path: '/site/[id]/reports/mentioned-keyword/[pageId]',
  },
  {
    name: 'site.settings',
    path: '/site/[id]/reports/settings',
  },
  {
    name: 'site.reports',
    path: '/site/[id]/reports/mentioned-keyword',
  },
  {
    name: 'site.reports.tracking',
    path: '/site/[id]/reports/page-tracking',
  },
  {
    name: 'site.reports.tracking.detail',
    path: '/site/[id]/reports/page-tracking/[pageTrackingId]',
  },
  {
    name: 'site.reports.tracking.report-detail',
    path: '/site/[id]/reports/page-tracking/[pageTrackingId]/[reportId]',
  },
  {
    name: 'site.changelog',
    path: '/site/[id]/reports/changelog',
  },
  {
    name: 'site.sitemap',
    path: '/site/[id]/reports/sitemap',
  },
  {
    name: 'site.sitemap.page',
    path: '/site/[id]/reports/sitemap/[sitemapId]',
  },
  {
    name: 'site.sitemap.page.history',
    path: '/site/[id]/reports/sitemap/[sitemapId]/[sitemapPageId]',
  },

  // report
  {
    name: 'site.report.pdf',
    path: '/site/[id]/reports',
  },
]
