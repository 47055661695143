import { FAILURE, REQUEST, SUCCESS } from '@constant/reducer-status'
import { FORGOT, LOGIN, LOGOUT, SIGNUP } from '@constant/auth'
import produce from 'immer'

const initialState = {
  login: {
    status: false,
    message: null,
  },
  singup: {
    status: false,
    message: null,
  },
  forgot: {
    status: false,
    message: null,
  },
  verify: {
    status: false,
    message: null,
  },
  changePassword: {
    status: false,
    message: null,
  },
}

export default produce((draft, action) => {
  switch (action.type) {
    case LOGIN.POST:
      draft.login.status = REQUEST
      draft.login.message = null
      break
    case LOGIN.SUCCESS:
      draft.login.status = SUCCESS
      draft.login.message = action.payload
      break
    case LOGIN.FAILURE:
      draft.login.status = FAILURE
      draft.login.message = action.payload.errors || {}
      break
    case SIGNUP.POST:
      draft.singup.status = REQUEST
      break
    case SIGNUP.SUCCESS:
      draft.singup.status = SUCCESS
      draft.singup.message = action.payload
      break
    case SIGNUP.FAILURE:
      draft.singup.status = FAILURE
      draft.singup.message = action.payload.errors || {}
      break
    case LOGOUT.POST:
      break

    // forgot

    case FORGOT.POST:
      draft.forgot = {
        status: REQUEST,
        message: '',
      }
      break
    case FORGOT.POST_SUCCES:
      draft.forgot = {
        status: SUCCESS,
        message: action.payload.message,
      }
      break
    case FORGOT.POST_FAILURE:
      draft.forgot = {
        status: FAILURE,
        message: action.payload.message || 'Failed to sent email',
      }
      break

    case FORGOT.VERIFY:
      draft.verify = {
        status: REQUEST,
        message: '',
      }
      break
    case FORGOT.VERIFY_SUCCESS:
      draft.verify = {
        status: SUCCESS,
        data: action.payload.data,
      }
      break
    case FORGOT.VERIFY_FAILURE:
      draft.verify = {
        status: FAILURE,
        message: action.payload.message || 'Token expired',
      }
      break

    case FORGOT.RESET_PASSWORD:
      draft.changePassword = {
        status: REQUEST,
        message: '',
      }
      break
    case FORGOT.RESET_PASSWORD_SUCCESS:
      draft.changePassword = {
        status: SUCCESS,
        data: action.payload.data,
      }
      break
    case FORGOT.RESET_PASSWORD_FAILURE:
      draft.changePassword = {
        status: FAILURE,
        message: action.payload.message || 'Token expired',
      }
      break
  }
}, initialState)
