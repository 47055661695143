import { call, debounce, fork, put, takeLatest } from 'redux-saga/effects'
import callApi from '@saga/api'
import { SEOTEST } from '@constant/seoTest'
import {
  detailTestSuccess,
  detailTestFailure,
  summaryTestSuccess,
  summaryTestFailure,
  historyTestFailure,
  historyTestSuccess,
  getTestListSuccess,
  getTestListFailure,
  dispatchTestSuccess,
  dispatchTestFailure,
  createTestFailure,
  createTestSuccess,
  updateTestSuccess,
  updateTestFailure,
  importTestFailure,
  importTestSuccess,
  deleteTestFailure,
  deleteTestSuccess,
} from '@action/seoTest'
import {
  createAdvanceTest,
  createBacklinkTest,
  createContentTest,
  createMetaTest,
  createRedirectTest,
  createSchematest,
  createTest,
  deleteTest,
  detailTest,
  detailTestHistory,
  detailTestSummary,
  dispatchTest,
  importExcel,
  testList,
  updateAdvanceTest,
  updateBacklinkTest,
  updateContentkTest,
  updateMetaTest,
  updateRedirectTest,
  updateSchemaTest,
  updateTest,
} from '@api/seoTest'
import { toast } from 'react-toastify'
import Router from 'next/router'
import { getRoute } from '@hook/router'

function* createTestSaga({ siteId, testType, data }) {
  let actionApi = createAdvanceTest
  switch (testType) {
    case 'schema':
      actionApi = createSchematest
      break
    case 'content':
      actionApi = createContentTest
      break
    case 'meta':
      actionApi = createMetaTest
      break
    case 'backlink':
      actionApi = createBacklinkTest
      break
    case 'redirect':
      actionApi = createRedirectTest
      break
  }
  const { ok, data: responseData } = yield call(callApi, actionApi, siteId, data)
  if (ok) {
    toast.success('Test created')
    yield put(createTestSuccess(siteId, responseData.data))
    yield Router.push(
      getRoute('site.seo.test', {
        id: siteId,
      })
    )
  } else {
    toast.error('Failed create test')
    yield put(createTestFailure(siteId, responseData.data))
  }
}

function* updateTestSaga({ siteId, data, seoTestId, testType }) {
  let actionApi = updateAdvanceTest
  switch (testType) {
    case 'schema':
      actionApi = updateSchemaTest
      break
    case 'content':
      actionApi = updateContentkTest
      break
    case 'meta':
      actionApi = updateMetaTest
      break
    case 'backlink':
      actionApi = updateBacklinkTest
      break
    case 'redirect':
      actionApi = updateRedirectTest
      break
  }
  const { ok, data: responseData } = yield call(callApi, actionApi, siteId, seoTestId, data)
  if (ok) {
    toast.success('Test updated')
    yield put(updateTestSuccess(siteId, responseData?.data))
    yield Router.push(
      getRoute('site.seo.test', {
        id: siteId,
      })
    )
  } else {
    toast.error('Failed update test')
    yield put(updateTestFailure(siteId, responseData?.data))
  }
}

function* listTestSaga({ siteId, seoTestType, status }) {
  const { ok, data } = yield call(callApi, testList, siteId, seoTestType, status)
  if (ok) {
    yield put(getTestListSuccess(siteId, data.data))
  } else {
    yield put(getTestListFailure(siteId, data.data))
  }
}

function* detailTestSaga({ siteId, seoTestId }) {
  const { ok, data } = yield call(callApi, detailTest, siteId, seoTestId)
  if (ok) {
    yield put(detailTestSuccess(seoTestId, data.data))
  } else {
    yield put(detailTestFailure(seoTestId, data.data))
  }
}

function* detailSummarySaga({ siteId, seoTestId, week }) {
  const { ok, data } = yield call(callApi, detailTestSummary, siteId, seoTestId, week)
  if (ok) {
    yield put(summaryTestSuccess(seoTestId, data.data))
  } else {
    yield put(summaryTestFailure(seoTestId, data.data))
  }
}

function* detailHistorySaga({ siteId, seoTestId, week }) {
  const { ok, data } = yield call(callApi, detailTestHistory, siteId, seoTestId, week)
  if (ok) {
    yield put(historyTestSuccess(seoTestId, data.data))
  } else {
    yield put(historyTestFailure(seoTestId, data.data))
  }
}

function* dispatchJobSaga({ siteId, seoTestId }) {
  const { ok, data } = yield call(callApi, dispatchTest, siteId, seoTestId)
  if (ok) {
    toast.success('Job has been dispatched, We will notify when job has been finished')
    yield put(dispatchTestSuccess(seoTestId, data.data))
  } else {
    yield put(dispatchTestFailure(seoTestId, data.data))
  }
}

function* importSeotestSaga({ siteId, data, callback }) {
  const { ok, data: response } = yield call(callApi, importExcel, siteId, {
    file: data,
  })

  if (ok) {
    yield put(importTestSuccess(siteId, response))
    yield callback()
    for (const seoTest of response.data || []) {
      yield toast.success(`${seoTest.name} imported`)
    }
    yield toast.success('Import Success')
  } else {
    yield put(importTestFailure(siteId, response))
    yield toast.error('Import failure')
  }
}

function* deleteTestSaga({ siteId, seoTestId }) {
  const { ok, data } = yield call(callApi, deleteTest, siteId, seoTestId)
  if (ok) {
    toast.success('SEO Test has been deleted')
    yield put(deleteTestSuccess(seoTestId, data.data))
  } else {
    toast.error('Failed to delete SEO Test')
    yield put(deleteTestFailure(seoTestId, data.data))
  }
  yield fork(listTestSaga, { siteId })
}

export default function* rootSaga() {
  yield takeLatest(SEOTEST.CREATE, createTestSaga)
  yield takeLatest(SEOTEST.UPDATE, updateTestSaga)
  yield debounce(50, SEOTEST.LIST, listTestSaga)
  yield debounce(50, SEOTEST.DETAIL_TEST, detailTestSaga)
  yield debounce(50, SEOTEST.DETAIL_SUMMARY_TEST, detailSummarySaga)
  yield debounce(50, SEOTEST.DETAIL_HISTORY_TEST, detailHistorySaga)
  yield takeLatest(SEOTEST.DISPATCH_TEST, dispatchJobSaga)
  yield takeLatest(SEOTEST.IMPORT_TEST, importSeotestSaga)
  yield takeLatest(SEOTEST.DELETE_TEST, deleteTestSaga)
}
