import { REPORT } from '@constant/report'

export const getReport = siteId => ({
  type: REPORT.GET_REPORT,
  siteId,
})

export const getReportSuccess = (siteId, payload) => ({
  type: REPORT.GET_REPORT_SUCCESS,
  payload,
  siteId,
})

export const getReportFailure = (siteId, payload) => ({
  type: REPORT.GET_REPORT_FAILURE,
  payload,
  siteId,
})

export const generate = (siteId, reportType) => ({
  type: REPORT.GENERATE,
  siteId,
  reportType,
})

export const generateSuccess = (siteId, payload) => ({
  type: REPORT.GENERATE_SUCCESS,
  siteId,
  payload,
})

export const generateFailure = (siteId, payload) => ({
  type: REPORT.GENERATE_FAILURE,
  siteId,
  payload,
})
