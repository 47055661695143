import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  :root {
    touch-action: pan-x pan-y;
    min-height: 100%;
    
  }
  html,
  body {
    padding: 0;
    margin: 0;
    background-color: ${({ theme }) => theme.colors.body};
    font-weight: 400;

  }
  
  img {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
    -webkit-touch-callout: none;
  }
  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.colors.primary}
  }
  button {
    cursor: pointer;
    border: unset;
  }
  * {
    box-sizing: border-box;
    outline: none;
  }
  .tether-element {
   z-index: 999; 
  }
  #nprogress {
    pointer-events: none;
      .bar {
          background: #29d;
          position: fixed;
          z-index: 1031;
          top: 0;
          left: 0;
          width: 100%;
          height: 2px;
      }
      .peg {
          display: block;
          position: absolute;
          right: 0px;
          width: 100px;
          height: 100%;
          box-shadow: 0 0 10px #29d, 0 0 5px #29d;
          opacity: 1;
          -webkit-transform: rotate(3deg) translate(0px, -4px);
          -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
      }
      .spinner {
          display: block;
          position: fixed;
          z-index: 1031;
          top: 15px;
          right: 15px;
      }
      .spinner-icon {
          width: 18px;
          height: 18px;
          box-sizing: border-box;
          border: solid 2px transparent;
          border-top-color: #29d;
          border-left-color: #29d;
          border-radius: 50%;
          -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
      }
  }
    .nprogress-custom-parent {
        overflow: hidden;
        position: relative;
    }
    .nprogress-custom-parent > #nprogress > .spinner,
    .nprogress-custom-parent > #nprogress > .bar {
        position: absolute;
    }
    @-webkit-keyframes nprogress-spinner {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
        }
        @keyframes nprogress-spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .video-js {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      min-height: 300px !important;
    }
    .vjs-big-play-button {
      height: 50px !important;
      width: 50px !important;
      border-radius: 100% !important;
      align-items: center !important;
      justify-content: center !important;
      top: unset !important;
      left: unset !important;
    }
    // emoji
    .text-emoji {
        img {
          height: 18px !important;
          width: 18px !important;
          margin-bottom: -2px;
        }
    }
  .tox-notification--warning {
    display: none !important;
  }
  
`
