import { webApi } from '@lib/api'

//create test
export const createAdvanceTest = (id, data) => webApi({ auth: true }).post(`/v1/site/${id}/seo-test/advance`, data)
export const createMetaTest = (id, data) => webApi({ auth: true }).post(`/v1/site/${id}/seo-test/meta`, data)
export const createSchematest = (id, data) => webApi({ auth: true }).post(`/v1/site/${id}/seo-test/schema`, data)
export const createRedirectTest = (id, data) => webApi({ auth: true }).post(`/v1/site/${id}/seo-test/redirect`, data)
export const createBacklinkTest = (id, data) => webApi({ auth: true }).post(`/v1/site/${id}/seo-test/backlink`, data)
export const createContentTest = (id, data) => webApi({ auth: true }).post(`/v1/site/${id}/seo-test/content`, data)

//update test
export const updateAdvanceTest = (id, seoTestId, data) =>
  webApi({ auth: true }).patch(`/v1/site/${id}/seo-test/advance/${seoTestId}`, data)
export const updateMetaTest = (id, seoTestId, data) =>
  webApi({ auth: true }).post(`/v1/site/${id}/seo-test/meta/${seoTestId}`, data)
export const updateSchemaTest = (id, seoTestId, data) =>
  webApi({ auth: true }).post(`/v1/site/${id}/seo-test/schema/${seoTestId}`, data)
export const updateRedirectTest = (id, seoTestId, data) =>
  webApi({ auth: true }).post(`/v1/site/${id}/seo-test/redirect/${seoTestId}`, data)
export const updateBacklinkTest = (id, seoTestId, data) =>
  webApi({ auth: true }).post(`/v1/site/${id}/seo-test/backlink/${seoTestId}`, data)
export const updateContentkTest = (id, seoTestId, data) =>
  webApi({ auth: true }).post(`/v1/site/${id}/seo-test/content/${seoTestId}`, data)

// delete test
export const deleteTest = (id, seoTestId) => webApi({ auth: true }).delete(`/v1/site/${id}/seo-test/${seoTestId}`)

//list all testing
export const testList = (id, type, status) =>
  webApi({ auth: true }).get(`/v1/site/${id}/seo-test`, {
    type,
    status,
  })

// detail test
export const detailTest = (siteId, seoTestId) => webApi({ auth: true }).get(`/v1/site/${siteId}/seo-test/${seoTestId}`)

// detail test
export const dispatchTest = (siteId, seoTestId) =>
  webApi({ auth: true }).post(`/v1/site/${siteId}/seo-test/${seoTestId}/dispatch`)

// summary test
export const detailTestSummary = (siteId, seoTestId, week) =>
  webApi({ auth: true }).get(`/v1/site/${siteId}/seo-test/${seoTestId}/summary`, { week })

// history test
export const detailTestHistory = (siteId, seoTestId, week) =>
  webApi({ auth: true }).get(`/v1/site/${siteId}/seo-test/${seoTestId}/history`, { week })

// import single test
export const importExcel = (siteId, data) =>
  webApi({ auth: true }).post(`/v1/site/${siteId}/seo-test/import`, data, {
    formData: true,
  })
