import { IDDLE, REQUEST } from '@constant/reducer-status'

const sampleState = {
  trackingQuery: {
    type: 'web',
    state: 'final',
    date: { startDate: null, endDate: null, type: 'LAST_3_MONTHS' },
    queries: {
      query: { expression: null, operator: 'contains' },
      country: { expression: null, operator: 'contains' },
      page: { expression: null, operator: 'contains' },
      device: { expression: null, operator: 'contains' },
      searchAppearance: { expression: null, operator: 'contains' },
    },
  },
  compareQuery: {
    type: null,
    state: 'final',
    date: { startDate: null, endDate: null, type: null },
    queries: {
      query: { expression: null, operator: 'contains' },
      country: { expression: null, operator: 'contains' },
      page: { expression: null, operator: 'contains' },
      device: { expression: null, operator: 'contains' },
      searchAppearance: { expression: null, operator: 'contains' },
    },
  },
  trackingDetail: { status: false, filter: {}, data: [] },
  trackingImport: { status: false, data: [] },
  seoTestDetail: { status: REQUEST, data: {} },
  seoTestSummary: { status: REQUEST, data: {} },
  seoTestHistory: { status: REQUEST, data: [] },
  seoTestList: { status: REQUEST, data: [] },
  seoTestCreate: { status: REQUEST, data: {}, errors: {} },
  seoTestUpdate: { status: REQUEST, data: {}, errors: {} },
  seoTestImport: { status: REQUEST, data: {}, errors: {} },
  missingKeywordPage: { status: REQUEST, data: [], errors: {} },
  missingKeywordPageList: { status: REQUEST, data: [], errors: {} },
  missingKeywordQuery: { total: 0, timestamp: null },
  misingKeywordPageData: { status: REQUEST, errors: {}, data: [] },
  trackingTag: { status: REQUEST, errors: {}, data: [] },
  trackingOverview: { status: REQUEST, errors: {}, data: [] },
  mentionedInMeta: { status: REQUEST, errors: {}, data: [] },
  changelogCreate: { status: REQUEST, errors: {}, data: {} },
  changelogUpdate: { status: REQUEST, errors: {}, data: {} },
  changelogDelete: { status: REQUEST, errors: {}, data: {} },
  changelog: { status: REQUEST, errors: {}, data: [] },
  siteMeta: { status: REQUEST, errors: {}, data: [] },
  sitemapMonitoringList: { status: REQUEST, errors: {}, data: [] },
  sitemapMonitoringDetail: { status: REQUEST, errors: {}, data: {} },
  sitemapMonitoringPage: { status: REQUEST, errors: {}, data: [] },
  sitemapMonitoringPageDetail: { status: REQUEST, errors: {}, data: {} },
  sitemapMonitoringPageHistory: { status: REQUEST, errors: {}, data: [] },
  sitemapMonitoringCreate: { status: REQUEST, errors: {}, data: [] },
  sitemapMonitoringUpdate: { status: REQUEST, errors: {}, data: [] },
  sitemapMonitoringDelete: { status: REQUEST, errors: {}, data: [] },
  report: { status: REQUEST, errors: {}, data: [] },
  pageTracking: {
    status: REQUEST,
    errors: {},
    data: [],
  },
  pageTrackingDetail: {
    status: REQUEST,
    errors: {},
    data: {},
  },
  pageTrackingReport: {
    status: REQUEST,
    errors: {},
    data: [],
  },
  pageTrackingReportHistory: {
    status: REQUEST,
    errors: {},
    data: [],
  },
  pageTrackingCreate: {
    status: IDDLE,
    errors: {},
    data: {},
  },
  pageTrackingReportMore: {
    status: IDDLE,
    errors: {},
    data: {},
  },

  pageTrackingChartPublisable: {
    status: IDDLE,
    errors: {},
    data: {},
  },
  pageTrackingChartHistory: {
    status: IDDLE,
    errors: {},
    data: {},
  },
}

export default sampleState
