import { call, debounce, put, takeLatest } from 'redux-saga/effects'
import callApi from '@saga/api'
import { forgot, forgotUpdatePassword, forgotVerify, login, loginOauth, logout, signup } from '@api/auth'
import { FORGOT, LOGIN, LOGOUT, SIGNUP } from '@constant/auth'
import Cookie from '@lib/cookie'
import Router from 'next/router'
import { getRoute } from '@hook/router'
import {
  forgotFailure,
  forgotSuccess,
  forgotVerifyFailure,
  forgotVerifySuccess,
  loginFailure,
  loginSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  signupFailure,
  signupSuccess,
} from '@action/auth'
import { flushStorage } from '@lib/storage/persisted-storage'
import { toast } from 'react-toastify'

function* loginSaga({ data }) {
  const { ok, data: response } = yield call(callApi, data?.code ? loginOauth : login, data)
  if (ok) {
    Cookie.set('--app-auth-token', response.data.token)
    Cookie.set('--active-site-id', 1)
    if (response?.data?.subscription?.status == 'INVALID') {
      yield put(loginSuccess(response))
      toast.warning('You need to update the payment method')
      window.location.href = getRoute('settings.billing')
    } else {
      toast.success('Login Success')
      setTimeout(() => {
        window.location.href = getRoute('dashboard.home')
      }, 1000)
    }
    //
    //
    // window.location.href = getRoute('dashboard.home')
  } else {
    toast.error('Login Failure')
    yield put(loginFailure(response.data))
  }
}

function* signupSaga({ data }) {
  const { ok, data: response } = yield call(callApi, signup, data)

  if (ok) {
    toast.success('Signup Success')
    yield put(signupSuccess(response.data))
    Cookie.set('--app-auth-token', response.data.token)
    Cookie.set('--active-site-id', 1)
    window.location.href = getRoute('dashboard.home')
  } else {
    toast.error('Signup Failure')
    yield put(signupFailure(response.data))
  }
}

function* logoutSaga() {
  yield call(callApi, logout)
  yield flushStorage()
  yield Cookie.remove('--app-auth-token')
  yield Cookie.remove('--active-site-id')
  yield Router.push(getRoute('auth.login'))
}

function* forgotSaga({ email }) {
  const { ok, data } = yield call(callApi, forgot, email)

  if (ok) {
    yield put(forgotSuccess(data))
  } else {
    yield put(forgotFailure(data))
  }
}

function* forgotVerifySaga({ token }) {
  const { ok, data } = yield call(callApi, forgotVerify, token)

  if (ok) {
    yield put(forgotVerifySuccess(data))
  } else {
    yield put(forgotVerifyFailure(data))
  }
}

function* forgotChangePaswordSaga({ data }) {
  const formData = {
    token: data.token,
    password: data.password,
    password_confirmation: data.passwordConfirmation,
  }
  const { ok, data: response } = yield call(callApi, forgotUpdatePassword, formData)
  if (ok) {
    yield put(resetPasswordSuccess(response))
    setTimeout(() => {
      Router.push(getRoute('auth.login'))
    }, 1000)
  } else {
    yield put(resetPasswordFailure(response))
  }
}

export default function* rootSaga() {
  yield takeLatest(LOGIN.POST, loginSaga)
  yield takeLatest(SIGNUP.POST, signupSaga)
  yield takeLatest(LOGOUT.POST, logoutSaga)
  yield takeLatest(FORGOT.POST, forgotSaga)
  yield takeLatest(FORGOT.RESET_PASSWORD, forgotChangePaswordSaga)
  yield debounce(10, FORGOT.VERIFY, forgotVerifySaga)
}
