import { CATEGORY } from '@constant/category'

export const getCategory = () => ({
  type: CATEGORY.GET,
})

export const getCategoryFailure = payload => ({
  type: CATEGORY.GET_FAILURE,
  payload,
})

export const getCategorySuccess = payload => ({
  type: CATEGORY.GET_SUCCESS,
  payload,
})
