import { APP } from '@constant/app'
import produce, { current } from 'immer'
import { MENU } from '@constant/menu'

const initialState = {
  menu: {
    expand: false,
  },
  popup: {},
  lastVersion: 'NEW_LOAD',
}

export default produce((draft, action) => {
  const currentState = current(draft)
  switch (action.type) {
    case APP.MENU.EXPAND:
      draft.menu.expand = true
      break
    case APP.MENU.COLLAPSE:
      draft.menu.expand = false
      break
    case APP.MENU.TOGGLE:
      draft.menu.expand = !currentState.menu.expand
      break
    case MENU.SET_POPUP:
      draft.popup[action.name] = action.data || true
      break
    case MENU.TOGGLE_POPUP:
      if (currentState.popup[action.name]) {
        delete draft.popup[action.name]
      } else {
        draft.popup[action.name] = action.data || true
      }
      break
    case APP.APP.SET_LAST_VERSION:
      draft.lastVersion = action.version
      break
    case MENU.REMOVE_POPUP:
      delete draft.popup[action.name]
      break
  }
}, initialState)
