import { getRoute } from '@hook/router'
import Link from 'next/link'

function ReportAlert({ siteId, text, downloadUrl }) {
  return (
    <div className="flex flex-col">
      <span>{text}</span>
      <div className="w-full flex mt-2 justify-end">
        <a
          href={downloadUrl}
          target="_blank"
          className="bg-primary text-white rounded py-1 hover:bg-opacity-80 text-xs px-3"
        >
          Download
        </a>
        <Link href={getRoute('site.report.pdf', { id: siteId })}>
          <button className="border-1 border-primary border-solid text-primary ml-2  text-xs rounded hover:bg-primary hover:text-white py-1 px-3">
            View All
          </button>
        </Link>
      </div>
    </div>
  )
}

export default ReportAlert
