import JSCookie from 'js-cookie'
import Cookies from 'cookies'
import moment from '@lib/moment'

const COOKIE_EXPIRED = 360 * 100 * 1000
const FRONT_EXPIRES_COOKIE = 365 * 10

export default class Cookie {
  constructor(ctx) {
    this.ctx = ctx
  }

  // server cookie initialize
  cookieCxt() {
    return new Cookies(this?.ctx?.req, this?.ctx?.res)
  }

  // server cookie
  get(name) {
    try {
      if (this?.ctx?.req) {
        return this.cookieCxt().get(name) //decode(this.cookieCxt().get(name))
      } else {
        return JSCookie.get(name) //decode(JSCookie.get(name))
      }
    } catch (e) {
      console.warn('Cookie Get Exception: ', e)
      return null
    }
  }
  set(name, value) {
    try {
      if (this?.ctx?.req) {
        //encode(value)
        return this.cookieCxt().set(name, value, {
          expires: moment().add(COOKIE_EXPIRED).toDate(),
          httpOnly: false,
        })
      } else {
        return JSCookie.set(name, value, {
          expires: FRONT_EXPIRES_COOKIE,
        })
      }
    } catch (e) {
      console.warn('Cookie Set Exception: ', e)
      return null
    }
  }
  remove(name) {
    try {
      if (this?.ctx?.req) {
        return this.cookieCxt().set(name, null)
      } else {
        return JSCookie.remove(name)
      }
    } catch (e) {
      console.warn('Cookie Remove Exception: ', e)
      return null
    }
  }

  // client cookie
  static get(name) {
    try {
      return JSCookie.get(name)
    } catch (e) {
      console.warn('Cookie Get Exception: ', e)
      return null
    }
  }
  static set(name, value) {
    try {
      return JSCookie.set(name, value, {
        expires: FRONT_EXPIRES_COOKIE,
      })
    } catch (e) {
      console.warn('Cookie Set Exception: ', e)
      return null
    }
  }
  static remove(name) {
    try {
      return JSCookie.remove(name)
    } catch (e) {
      console.warn('Cookie Remove Exception: ', e)
      return null
    }
  }
}
