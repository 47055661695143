import { combineReducers } from 'redux'
import { createPersistable } from '@lib/storage/persisted-storage'

// auth
import auth from '@reducer/auth'
// app
import appDefault from '@reducer/app'
import website from '@reducer/site'
import credential from '@reducer/credential'
import profile from '@reducer/profile'
import category from '@reducer/category'
import tag from '@reducer/tag'
import country from '@reducer/country'

const persistedWebsite = createPersistable(website, 'website', {
  blacklist: ['create', 'trackingQuery', 'compareQuery', 'seoTestCreate', 'seoTestImport'],
})
const persistedCredential = createPersistable(credential, 'credential', {
  blacklist: ['create'],
})
const persistedApp = createPersistable(appDefault, 'app', {
  blacklist: ['popup', 'create', 'availableSites'],
})
const persistedprofile = createPersistable(profile, 'profile', {
  blacklist: ['updateProfile'],
})
const persistedCategory = createPersistable(category, 'category')
const persistedTag = createPersistable(tag, 'tag')
const persistedCountry = createPersistable(country, 'country')
/**
 * App default
 */
const app = {
  auth,
  site: persistedWebsite,
  credential: persistedCredential,
  app: persistedApp,
  profile: persistedprofile,
}

const masterData = {
  category: persistedCategory,
  tag: persistedTag,
  country: persistedCountry,
}

/**
 * Export
 */
export default combineReducers({
  ...app,
  ...masterData,
})
