import { all } from 'redux-saga/effects'
import auth from '@saga/auth'
import site from '@saga/site'
import siteFilter from '@saga/site/filter'
import siteTracking from '@saga/site/tracking'
import credential from '@saga/credential'
import appMain from '@saga/app'
import profile from '@saga/profile'
import category from '@saga/category'
import tag from '@saga/tag'
import socket from '@saga/socket'
import country from '@saga/country'
import seoTest from '@saga/seoTest'
import siteReport from '@saga/site/report'
import siteSitemap from '@saga/site/sitemap'

// chats
// merge utilities

const app = [
  auth(),
  site(),
  credential(),
  appMain(),
  profile(),
  siteTracking(),
  country(),
  seoTest(),
  siteReport(),
  siteSitemap(),
]

// master
const masterData = [siteFilter(), category(), tag()]

// sagas call
function* rootSaga() {
  yield all([...app, ...masterData, socket()])
}

export default rootSaga
