import { MENU } from '@service/constants/menu'

export const openPopup = (name, data = {}) => ({
  type: MENU.SET_POPUP,
  name,
  data,
})

export const togglePopup = (name, data = {}) => ({
  type: MENU.TOGGLE_POPUP,
  name,
  data,
})

export const closePopup = name => ({
  type: MENU.REMOVE_POPUP,
  name,
})

export const setEditorHeight = height => ({
  type: MENU.SET_EDITOR_HEIGHT,
  height,
})

// notice

export const setNotice = (notice, status = 'info') => ({
  type: MENU.ADD_NOTICE,
  notice,
  status,
})
