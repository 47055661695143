import { FAILURE, REQUEST, SUCCESS } from '@constant/reducer-status'
import { PROFILE } from '@constant/profile'
import produce from 'immer'

const initialState = {
  profile: {
    status: false,
    data: {},
  },
  updateProfile: {
    status: false,
    data: {},
  },
  subscription: {
    status: false,
    data: {},
  },
  subscriptionStatus: {
    status: false,
    data: {},
  },
}

export default produce((draft, action) => {
  switch (action.type) {
    case PROFILE.GET_PROFILE:
      draft.profile.status = REQUEST
      break
    case PROFILE.GET_PROFILE_SUCCESS:
      draft.profile.status = SUCCESS
      draft.profile.data = action.payload
      break
    case PROFILE.GET_PROFILE_FAILURE:
      draft.profile.status = FAILURE
      break
    case PROFILE.UPDATE_PROFILE:
      draft.updateProfile.status = REQUEST
      break
    case PROFILE.UPDATE_PROFILE_SUCCESS:
      draft.updateProfile.status = SUCCESS
      draft.updateProfile.data = action.payload
      break
    case PROFILE.UPDATE_PROFILE_FAILURE:
      draft.updateProfile.status = FAILURE
      break
    case PROFILE.GET_PROFILE_SUBSCRIPTION:
      draft.subscription.status = REQUEST
      break
    case PROFILE.GET_PROFILE_SUBSCRIPTION_SUCCESS:
      draft.subscription.status = SUCCESS
      draft.subscription.data = action.payload
      break
    case PROFILE.GET_PROFILE_SUBSCRIPTION_FAILURE:
      draft.subscription.status = FAILURE
      break

    case PROFILE.GET_PROFILE_SUBSCRIPTION_STATUS:
      draft.subscriptionStatus.status = REQUEST
      break
    case PROFILE.GET_PROFILE_SUBSCRIPTION_STATUS_SUCCESS:
      draft.subscriptionStatus.status = SUCCESS
      draft.subscriptionStatus.data = action.payload.data
      break
    case PROFILE.GET_PROFILE_SUBSCRIPTION_STATUS_FAILURE:
      draft.subscriptionStatus.status = FAILURE
      draft.subscriptionStatus.data = action.payload.data
      break
  }
}, initialState)
