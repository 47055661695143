import { COUNTRY } from '@constant/country'
import { FAILURE, REQUEST, SUCCESS } from '@constant/reducer-status'
import produce from 'immer'

const initialState = {
  data: [],
  status: false,
}

export default produce((draft, action) => {
  switch (action.type) {
    case COUNTRY.GET:
      draft.data = []
      draft.status = REQUEST
      break
    case COUNTRY.GET_SUCCESS:
      draft.data = action.payload.data
      draft.status = SUCCESS
      break
    case COUNTRY.GET_FAILURE:
      draft.data = []
      draft.status = FAILURE
      break
  }
}, initialState)
