export const APP = {
  MENU: {
    COLLAPSE: 'APP_MENU_COLLAPSE',
    EXPAND: 'APP_MENU_EXPAND',
    TOGGLE: 'APP_MENU_TOGGLE',
  },
  APP: {
    INIT: 'APP_INIT',
    SET_LAST_VERSION: 'APP_SET_LAST_VERSION',
    UPDATE_VERSION: 'APP_UPDATE_VERSION',
  },
  SITE: {
    INIT: 'SITE_INIT',
  },
}
