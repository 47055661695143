import { call, debounce, fork, put, select, takeLatest, throttle } from 'redux-saga/effects'
import callApi from '@saga/api'
import {
  createChangelog,
  createSite,
  deleteChangelog,
  detail,
  getAvailableSites,
  getChangelog,
  getMeta,
  listSite,
  overview,
  siteSync,
  updateChangelog,
} from '@api/site'
import { SITE } from '@constant/site'
import dates, { compareDate } from '@constant/date'
import {
  createLogFailure,
  createLogSuccess,
  createSiteFailure,
  createSiteSuccess,
  getChangelogFailure,
  getChangelogSuccess,
  getMetaFailure,
  getMetaSuccess,
  getVailableSiteFailure,
  getVailableSiteSuccess,
  setSiteDetail,
  setSiteList,
  setSiteOverview,
  setSiteOverviewCompare,
  updateChangelogFailure,
  updateChangelogSuccess,
} from '@action/site'
import Router from 'next/router'
import { getTrackerCompareSelector, getTrackerSelector } from '@service/selectors/site'
import { getRoute } from '@hook/router'
import moment from '@lib/moment'
import { toast } from 'react-toastify'

function* listSiteSaga({ search }) {
  if (window.latestFetchSite == search) {
    return 0
  }
  const { ok, data: response } = yield call(callApi, listSite, { search })
  if (ok) {
    window.latestFetchSite = search
    yield put(setSiteList(response))
  }
}

function* createSiteSaga({ data, callback }) {
  const { ok, data: response } = yield call(callApi, createSite, data)
  if (ok) {
    yield call(detailSiteSaga, {
      siteId: response.data.id,
    })
    yield put(createSiteSuccess(response))
    yield fork(listSiteSaga, {})
    if (callback) {
      yield callback()
    }
    yield Router.push(
      getRoute('site.detail', {
        id: response.data.id,
      })
    )
    // yield Router.push(getRoute('dashboard.home'))
  } else {
    yield put(createSiteFailure(response))
  }
}

function* detailSiteSaga({ siteId }) {
  if (window?.latestFetchSiteId == siteId) {
    return 0
  }
  const { ok, data: response } = yield call(callApi, detail, siteId)
  if (ok) {
    window.latestFetchSiteId = siteId
    yield put(setSiteDetail(response))
  }
}

function* overviewSiteSaga({ dimension, siteId }) {
  const [date, type, queries, state] = yield select(getTrackerSelector)
  let parsedQueries = []

  let startDate = moment().subtract(3, 'days').format('YYYY-MM-DD')
  let endDate = moment().format('YYYY-MM-DD')

  if (date?.type == 'CUSTOM' || date?.type == 'CUSTOM_COMPARE') {
    startDate = date.startDate
    endDate = date.endDate
  } else {
    const dateAction = [...dates, ...compareDate].find(dt => dt.value == date.type)
    const dateFunc = dateAction.date()
    startDate = dateFunc.startDate || dateFunc?.target?.startDate
    endDate = dateFunc.endDate || dateFunc?.target?.endDate
  }

  // console.log('RTS', startDate, endDate)

  if (queries?.country?.expression) {
    parsedQueries.push({
      expression: queries.country.expression,
      operator: 'equals',
      dimension: 'COUNTRY',
    })
  }
  if (queries?.device?.expression) {
    parsedQueries.push({
      expression: queries.device.expression,
      operator: 'equals',
      dimension: 'DEVICE',
    })
  }
  if (queries?.page?.expression) {
    parsedQueries.push({
      expression: queries.page.expression,
      operator: queries.page.operator,
      dimension: 'PAGE',
    })
  }
  if (queries?.query?.expression) {
    parsedQueries.push({
      expression: queries.query.expression,
      operator: queries.query.operator,
      dimension: 'QUERY',
    })
  }
  if (queries?.searchAppearance?.expression) {
    parsedQueries.push({
      expression: queries.searchAppearance.expression,
      operator: 'equals',
      dimension: 'SEARCH_APPEARANCE',
    })
  }

  // 'PAGE', 'COUNTRY'
  const query = {
    dimension: [dimension],
    type,
    state: state,
    startDate,
    endDate,
    queries: parsedQueries,
  }
  const { ok, data: response } = yield call(callApi, overview, siteId, query)
  if (ok) {
    yield put(setSiteOverview(response, siteId))
  } else {
    console.warn(response)
  }
}

function* overviewCompareSaga({ dimension, siteId }) {
  let canCompare = false
  const [date, type, queries, state] = yield select(getTrackerSelector)
  const [comparedDate, comparedType, comparedQueries] = yield select(getTrackerCompareSelector)
  let parsedQueries = []

  let startDate = moment().subtract(3, 'days').format('YYYY-MM-DD')
  let endDate = moment().format('YYYY-MM-DD')

  // for date
  if (comparedDate?.type) {
    if (comparedDate?.type == 'CUSTOM' || comparedDate?.type == 'CUSTOM_COMPARE') {
      startDate = comparedDate.startDate
      endDate = comparedDate.endDate
    } else {
      const dateAction = [...dates, ...compareDate].find(dt => dt.value == comparedDate.type)
      const dateFunc = dateAction.date()
      startDate = dateFunc.startDate || dateFunc?.compare?.startDate
      endDate = dateFunc.endDate || dateFunc?.compare?.endDate
    }
  } else {
    if (date?.type == 'CUSTOM' || date?.type == 'CUSTOM_COMPARE') {
      startDate = date.startDate
      endDate = date.endDate
    } else {
      const dateAction = [...dates, ...compareDate].find(dt => dt.value == date.type)
      const dateFunc = dateAction.date()
      startDate = dateFunc.startDate || dateFunc?.compare?.startDate
      endDate = dateFunc.endDate || dateFunc?.compare?.endDate
    }
  }

  if (comparedQueries?.country?.expression) {
    parsedQueries.push({
      expression: comparedQueries.country.expression,
      operator: 'equals',
      dimension: 'COUNTRY',
    })
  } else if (queries?.country?.expression) {
    parsedQueries.push({
      expression: queries.country.expression,
      operator: 'equals',
      dimension: 'COUNTRY',
    })
  }

  if (comparedQueries?.device?.expression) {
    parsedQueries.push({
      expression: comparedQueries.device.expression,
      operator: 'equals',
      dimension: 'DEVICE',
    })
  } else if (queries?.device?.expression) {
    parsedQueries.push({
      expression: queries.device.expression,
      operator: 'equals',
      dimension: 'DEVICE',
    })
  }

  if (comparedQueries?.page?.expression) {
    parsedQueries.push({
      expression: comparedQueries.page.expression,
      operator: comparedQueries.page.operator,
      dimension: 'PAGE',
    })
  } else if (queries?.page?.expression) {
    parsedQueries.push({
      expression: queries.page.expression,
      operator: queries.page.operator,
      dimension: 'PAGE',
    })
  }
  if (comparedQueries?.query?.expression) {
    parsedQueries.push({
      expression: comparedQueries.query.expression,
      operator: comparedQueries.query.operator,
      dimension: 'QUERY',
    })
  } else if (queries?.query?.expression) {
    parsedQueries.push({
      expression: queries.query.expression,
      operator: queries.query.operator,
      dimension: 'QUERY',
    })
  }

  if (comparedQueries?.searchAppearance?.expression) {
    parsedQueries.push({
      expression: comparedQueries.searchAppearance.expression,
      operator: 'equals',
      dimension: 'SEARCH_APPEARANCE',
    })
  } else if (queries?.searchAppearance?.expression) {
    parsedQueries.push({
      expression: queries.searchAppearance.expression,
      operator: 'equals',
      dimension: 'SEARCH_APPEARANCE',
    })
  }

  const query = {
    dimension: [dimension],
    type: comparedType,
    state: state,
    startDate,
    endDate,
    queries: parsedQueries,
  }

  const { ok, data: response } = yield call(callApi, overview, siteId, query)
  if (ok) {
    yield put(setSiteOverviewCompare(response, siteId))
  } else {
    console.warn(response)
  }
}

function* resetSiteSaga() {
  yield call(detailSiteSaga, {})
  yield Router.reload()
}

function* syncSite({ siteId }) {
  const { ok } = yield call(callApi, siteSync, siteId)
  if (ok) {
    toast.success('Sync success, please wait for a while')
  }
}

function* getAvailableSiteSaga({ credentialId }) {
  const { ok, data } = yield call(callApi, getAvailableSites, credentialId)
  if (ok) {
    yield put(getVailableSiteSuccess(data))
  } else {
    yield put(getVailableSiteFailure(data))
  }
}

function* createChangelogSaga({ siteId, data, callback }) {
  const { ok, data: response } = yield call(callApi, createChangelog, siteId, data)
  if (ok) {
    toast.success('Changelog Created')
    yield put(createLogSuccess(response))
    if (callback) {
      yield callback()
    }
  } else {
    yield put(createLogFailure(response))
  }
}

function* updateChangelogSaga({ siteId, data, callback }) {
  const { ok, data: response } = yield call(callApi, updateChangelog, siteId, data.id, data)
  if (ok) {
    toast.success('Changelog Updated')
    yield put(updateChangelogSuccess(response))
    if (callback) {
      yield callback()
      yield fork(getChangelogSaga, { siteId })
    }
  } else {
    yield put(updateChangelogFailure(siteId, response))
  }
}

function* deleteChangelogSaga({ siteId, changelogId, callback }) {
  const { ok, data: response } = yield call(callApi, deleteChangelog, siteId, changelogId)
  if (ok) {
    toast.success('Changelog Deleted')
    yield put(updateChangelogSuccess(response))
    if (callback) {
      yield callback()
      yield fork(getChangelogSaga, { siteId })
    }
  } else {
    yield put(updateChangelogFailure(siteId, response))
  }
}

function* getChangelogSaga({ siteId, params }) {
  const { ok, data: response } = yield call(callApi, getChangelog, siteId, params)
  if (ok) {
    yield put(getChangelogSuccess(siteId, response?.data))
  } else {
    yield put(getChangelogFailure(siteId, response?.data))
  }
}

function* getMetaSaga({ siteId }) {
  const { ok, data: response } = yield call(callApi, getMeta, siteId)
  if (ok) {
    yield put(getMetaSuccess(siteId, response?.data))
  } else {
    yield put(getMetaFailure(siteId, response?.data))
  }
}

export default function* rootSaga() {
  yield debounce(100, SITE.LIST, listSiteSaga)
  yield throttle(1000, SITE.DETAIL, detailSiteSaga)
  yield debounce(10, SITE.OVERVIEW, overviewSiteSaga)
  yield debounce(10, SITE.OVERVIEW_PAGE, overviewSiteSaga)
  yield debounce(10, SITE.CREATE_SITE, createSiteSaga)
  yield debounce(10, SITE.SYNC, syncSite)
  yield debounce(10, SITE.OVERVIEW_COMPARE, overviewCompareSaga)
  yield debounce(10, SITE.OVERVIEW_COMPARE_NO_DEBOUNCE, overviewCompareSaga)
  yield debounce(100, SITE.GET_AVAILABLE_SITE, getAvailableSiteSaga)
  yield takeLatest(SITE.LOG_CREATE, createChangelogSaga)
  yield debounce(10, SITE.LOG_GET, getChangelogSaga)
  yield debounce(10, SITE.META_GET, getMetaSaga)
  yield takeLatest(SITE.LOG_UPDATE, updateChangelogSaga)
  yield takeLatest(SITE.LOG_DELETE, deleteChangelogSaga)
}
