import { APP } from '@constant/app'

export const openMenu = () => ({
  type: APP.MENU.EXPAND,
})

export const closeMenu = () => ({
  type: APP.MENU.COLLAPSE,
})

export const toggleMenu = () => ({
  type: APP.MENU.TOGGLE,
})

export const init = ({ siteId } = {}) => ({
  type: APP.APP.INIT,
  siteId,
})

export const siteInit = ({ siteId } = {}) => ({
  type: APP.SITE.INIT,
  siteId,
})

export const setLastVersion = version => ({
  type: APP.APP.SET_LAST_VERSION,
  version,
})

export const updateVersion = () => ({
  type: APP.APP.UPDATE_VERSION,
})
