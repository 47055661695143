import { call, debounce, put } from 'redux-saga/effects'
import callApi from '@saga/api'
import { getCategorySuccess } from '@action/category'
import { listCategory } from '@api/category'
import { CATEGORY } from '@/services/constants/category'

function* listCategorySaga() {
  const { ok, data: response } = yield call(callApi, listCategory)
  if (ok) {
    yield put(getCategorySuccess(response.data))
  }
}

export default function* rootSaga() {
  yield debounce(10, CATEGORY.GET, listCategorySaga)
}
