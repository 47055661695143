import { TAG } from '@constant/tag'

export const getTag = (siteId, types = []) => ({
  type: TAG.GET,
  siteId,
  types,
})

export const getTagFailure = payload => ({
  type: TAG.GET_FAILURE,
  payload,
})

export const getTagSuccess = payload => ({
  type: TAG.GET_SUCCESS,
  payload,
})
