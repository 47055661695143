import { SITE } from '@constant/site'

// list
export const getSiteList = (search = '') => ({
  type: SITE.LIST,
  search,
})

export const setSiteList = payload => ({
  type: SITE.LIST_SUCCESS,
  payload,
})

// create
export const createSite = (data, callback) => ({
  type: SITE.CREATE_SITE,
  data,
  callback,
})

export const createSiteSuccess = payload => ({
  type: SITE.CREATE_SITE_SUCCESS,
  payload,
})

export const createSiteFailure = payload => ({
  type: SITE.CREATE_SITE_FAILURE,
  payload,
})
//  detail
export const getSiteDetail = siteId => ({
  type: SITE.DETAIL,
  siteId,
})

export const setSiteDetail = payload => ({
  type: SITE.DETAIL_SUCCESS,
  payload,
})
// overiew
export const getSiteOverview = (siteId, dimension) => ({
  type: SITE.OVERVIEW,
  siteId,
  dimension,
})

export const getSiteOverviewNoDebounce = (siteId, dimension) => ({
  type: SITE.OVERVIEW_PAGE,
  dimension,
  siteId,
})

export const setSiteOverview = (payload, siteId) => ({
  type: SITE.OVERVIEW_SUCCESS,
  payload,
  siteId,
})

export const getSiteOverviewCompare = (siteId, dimension) => ({
  type: SITE.OVERVIEW_COMPARE,
  siteId,
  dimension,
})

export const getSiteOverviewCompareNoDebounce = (siteId, dimension) => ({
  type: SITE.OVERVIEW_COMPARE_NO_DEBOUNCE,
  siteId,
  dimension,
})

export const setSiteOverviewCompare = (payload, siteId) => ({
  type: SITE.OVERVIEW_COMPARE_SUCCESS,
  payload,
  siteId,
})

export const getSiteTracking = (siteId, filters) => ({
  type: SITE.TRACKING,
  siteId,
  filters,
})

export const setSiteTracking = (payload, siteId) => ({
  type: SITE.TRACKING_SUCCESS,
  payload,
  siteId,
})

// import tracking

export const importSiteTracking = (siteId, data, callback) => ({
  type: SITE.TRACKING_IMPORT,
  siteId,
  data,
  callback,
})

export const importSiteTrackingSuccess = (siteId, payload) => ({
  type: SITE.TRACKING_IMPORT_SUCCESS,
  payload,
  siteId,
})

export const importSiteTrackingFailure = (siteId, payload) => ({
  type: SITE.TRACKING_IMPORT_FAILURE,
  payload,
  siteId,
})

// filter detail
export const getSiteTrackingDetail = (siteId, trackingId, filter) => ({
  type: SITE.TRACKING_DETAIL,
  siteId,
  trackingId,
  filter,
})

export const setSiteTrackingDetail = (payload, trackingId) => ({
  type: SITE.TRACKING_DETAIL_SUCCESS,
  payload,
  trackingId,
})

// filter summary
export const getSiteTrackingSummary = (siteId, trackingId, filter) => ({
  type: SITE.TRACKING_SUMMARY,
  siteId,
  trackingId,
  filter,
})

export const getSiteTrackingSummarySuccess = (payload, trackingId) => ({
  type: SITE.TRACKING_SUMMARY_SUCCESS,
  payload,
  trackingId,
})
export const getSiteTrackingSummaryFailure = (payload, trackingId) => ({
  type: SITE.TRACKING_SUMMARY_FAILURE,
  payload,
  trackingId,
})

// create tracking
export const createTracking = (siteId, data, callback) => ({
  type: SITE.TRACKING_CREATE,
  siteId,
  data,
  callback,
})

export const createTrackinSuccess = data => ({
  type: SITE.TRACKING_CREATE_SUCCESS,
  data,
})

export const createTrackingFailure = data => ({
  type: SITE.TRACKING_CREATE_FAILURE,
  data,
})

// filters listing
export const getSiteFilterList = (siteId, trackingId) => ({
  type: SITE.FILTER_LIST,
  siteId,
  trackingId,
})

export const setSiteFilterList = payload => ({
  type: SITE.FILTER_LIST_SUCCESS,
  payload,
})

// filters listing
export const createSiteFilter = (siteId, data) => ({
  type: SITE.FILTER_CREATE,
  siteId,
  data,
})

export const createSiteFilterSuccess = payload => ({
  type: SITE.FILTER_CREATE_SUCCESS,
  payload,
})

export const setOverviewFilter = (filter, value) => ({
  type: SITE.SET_OVERVIEW_FILTER,
  filter,
  value,
})

/**
 * Manual sync tracking
 */

export const trackingResync = (siteId, trackerId) => ({
  type: SITE.TRACKING_SYNC,
  siteId,
  trackerId,
})

export const trackingResyncFailure = payload => ({
  type: SITE.TRACKING_SYNC_FAILURE,
  payload,
})

export const trackingResyncSuccess = payload => ({
  type: SITE.TRACKING_SYNC_SUCCESS,
  payload,
})

/**
 * Get available sites
 */

export const getVailableSite = credentialId => ({
  type: SITE.GET_AVAILABLE_SITE,
  credentialId,
})

export const getVailableSiteSuccess = payload => ({
  type: SITE.GET_AVAILABLE_SITE_SUCCESS,
  payload,
})

export const getVailableSiteFailure = payload => ({
  type: SITE.GET_AVAILABLE_SITE_FAILURE,
  payload,
})

// set date
export const setTrackingSummaryDate = ({ type, startDate, endDate, compare }, siteId) => ({
  type: SITE.SET_TRACKING_SUMMARY_DATE,
  siteId,
  data: {
    type,
    startDate,
    endDate,
  },
  compare,
})

// set summary type
export const setTrackingSummaryType = (type, compare, siteId) => ({
  type: SITE.SET_TRACKING_SUMMARY_TYPE,
  siteId,
  data: {
    type,
    compare,
  },
})

// set summary type
export const setTrackingDataType = (state, siteId) => ({
  type: SITE.SET_TRACKING_DATA_TYPE,
  siteId,
  data: {
    state,
  },
})
// set summary query
export const setTrackingSummaryQuery = ({ type, expression, operator, compare }, siteId) => ({
  type: SITE.SET_TRACKING_SUMMARY_QUERY,
  siteId,
  data: {
    type,
    expression,
    operator,
  },
  compare,
})

// resync site
export const syncSite = siteId => ({
  type: SITE.SYNC,
  siteId,
})

export const syncSiteFailure = payload => ({
  type: SITE.SYNC_FAILURE,
  payload,
})

export const syncSiteSuccess = payload => ({
  type: SITE.SYNC_SUCCESS,
  payload,
})

// delete tracker
export const deleteTracker = (siteId, trackerId, callback) => ({
  type: SITE.TRACKING_DELETE,
  siteId,
  trackerId,
  callback,
})

export const deleteTrackerFailure = payload => ({
  type: SITE.TRACKING_DELETE_FAILURE,
  payload,
})

export const deleteTrackerSuccess = payload => ({
  type: SITE.TRACKING_DELETE_SUCCESS,
  payload,
})

// site reports that
export const getPageQuery = (siteId, pageId) => ({
  type: SITE.GET_PAGE_QUERY,
  siteId,
  pageId,
})

export const getPageQuerySucces = (pageId, payload) => ({
  type: SITE.GET_PAGE_QUERY_SUCCESS,
  payload,
  pageId,
})

export const getPageQueryFailure = (pageId, payload) => ({
  type: SITE.GET_PAGE_QUERY_FAILURE,
  payload,
  pageId,
})

// site reports query counter
export const getPageQueryCounter = (siteId, page, query) => ({
  type: SITE.GET_QUERY_COUNTER,
  siteId,
  page,
  query,
})

export const getPageQueryCounterSuccess = (payload, cacheName, query) => ({
  type: SITE.GET_QUERY_COUNTER_SUCCESS,
  cacheName,
  query,
  payload,
})

export const getPageQueryCounterFailure = (payload, cacheName, query) => ({
  type: SITE.GET_QUERY_COUNTER_FAILURE,
  cacheName,
  query,
  payload,
})

// reset reports query counter
export const resetPageQueryCounter = (siteId, page) => ({
  type: SITE.RESET_QUERY_COUNTER,
  siteId,
  page,
})

export const resetPageQueryCounterSuccess = pageName => ({
  type: SITE.RESET_QUERY_COUNTER_SUCCESS,
  pageName,
})

export const resetPageQueryCounterFailure = pageName => ({
  type: SITE.RESET_QUERY_COUNTER_FAILURE,
  pageName,
})

// get mentioned pages
export const getMentionedPage = (siteId, page, filter, sort) => ({
  type: SITE.GET_MISSING_KEYWORD_PAGE,
  siteId,
  filter,
  page,
  sort,
})

export const getMentionedPageSuccess = (siteId, page, payload) => ({
  type: SITE.GET_MISSING_KEYWORD_PAGE_SUCCESS,
  page,
  siteId,
  payload,
})

export const getMentionedPageFailure = (siteId, page, payload) => ({
  type: SITE.GET_MISSING_KEYWORD_PAGE_FAILURE,
  page,
  siteId,
  payload,
})

// get mentioned pages
export const getMentionedPageList = siteId => ({
  type: SITE.GET_MISSING_KEYWORD_PAGE_LIST,
  siteId,
})

export const getMentionedPageListSuccess = (siteId, payload) => ({
  type: SITE.GET_MISSING_KEYWORD_PAGE_LIST_SUCCESS,
  siteId,
  payload,
})

export const getMentionedPageListFailure = (siteId, payload) => ({
  type: SITE.GET_MISSING_KEYWORD_PAGE_LIST_FAILURE,
  siteId,
  payload,
})

// get mentioned pages
export const getTrackingTag = siteId => ({
  type: SITE.TRACKING_TAG_GET,
  siteId,
})

export const getTrackingTagSuccess = (siteId, payload) => ({
  type: SITE.TRACKING_TAG_GET_SUCCESS,
  siteId,
  payload,
})

export const getTrackingTagFailure = (siteId, payload) => ({
  type: SITE.TRACKING_TAG_GET_FAILURE,
  siteId,
  payload,
})

export const exportTrackingTag = siteId => ({
  type: SITE.TRACKING_TAG_EXPORT,
  siteId,
})

// get overvview
export const getTrackingOverview = (siteId, filters) => ({
  type: SITE.TRACKING_OVERVIEW_GET,
  filters,
  siteId,
})

export const getTrackingOverviewSuccess = (siteId, payload) => ({
  type: SITE.TRACKING_OVERVIEW_GET_SUCCESS,
  siteId,
  payload,
})

export const getTrackingOverviewFailure = (siteId, payload) => ({
  type: SITE.TRACKING_OVERVIEW_GET_FAILURE,
  siteId,
  payload,
})

// get overview
export const getMentionedInMeta = siteId => ({
  type: SITE.GET_MENTIONED_IN_META,
  siteId,
})

export const getMentionedInMetaSuccess = (siteId, payload) => ({
  type: SITE.GET_MENTIONED_IN_META_SUCCESS,
  siteId,
  payload,
})

export const getMentionedInMetaFailure = (siteId, payload) => ({
  type: SITE.GET_MENTIONED_IN_META_FAILURE,
  siteId,
  payload,
})

// create changelog
export const createLog = (siteId, data, callback) => ({
  type: SITE.LOG_CREATE,
  siteId,
  data,
  callback,
})

export const createLogSuccess = (siteId, payload) => ({
  type: SITE.LOG_CREATE_SUCCESS,
  siteId,
  payload,
})

export const createLogFailure = (siteId, payload) => ({
  type: SITE.LOG_CREATE_FAILURE,
  siteId,
  payload,
})

// get changelog
export const getChangelog = (siteId, params) => ({
  type: SITE.LOG_GET,
  params,
  siteId,
})

export const getChangelogSuccess = (siteId, payload) => ({
  type: SITE.LOG_GET_SUCCESS,
  siteId,
  payload,
})

export const getChangelogFailure = (siteId, payload) => ({
  type: SITE.LOG_GET_FAILURE,
  siteId,
  payload,
})

// update changelog
export const updateChangelog = (siteId, data, callback) => ({
  type: SITE.LOG_UPDATE,
  data,
  callback,
  siteId,
})

export const updateChangelogSuccess = (siteId, payload) => ({
  type: SITE.LOG_UPDATE_SUCCESS,
  siteId,
  payload,
})

export const updateChangelogFailure = (siteId, payload) => ({
  type: SITE.LOG_UPDATE_FAILURE,
  siteId,
  payload,
})

// delete changelog
export const deleteChangelog = (siteId, changelogId, callback) => ({
  type: SITE.LOG_DELETE,
  changelogId,
  callback,
  siteId,
})

export const deleteChangelogSuccess = (siteId, payload) => ({
  type: SITE.LOG_DELETE_SUCCESS,
  siteId,
  payload,
})

export const deleteChangelogFailure = (siteId, payload) => ({
  type: SITE.LOG_DELETE_FAILURE,
  siteId,
  payload,
})

// site meta data
export const getMeta = siteId => ({
  type: SITE.META_GET,
  siteId,
})

export const getMetaSuccess = (siteId, payload) => ({
  type: SITE.META_GET_SUCCESS,
  siteId,
  payload,
})

export const getMetaFailure = (siteId, payload) => ({
  type: SITE.META_GET_FAILURE,
  siteId,
  payload,
})

// sitemap
// sitemap all
export const getSitemapMonitor = siteId => ({
  type: SITE.SITEMAP_MONITOR_GET,
  siteId,
})

export const getSitemapMonitorSuccess = (siteId, payload) => ({
  type: SITE.SITEMAP_MONITOR_GET_SUCCESS,
  siteId,
  payload,
})

export const getSitemapMonitorFailure = (siteId, payload) => ({
  type: SITE.SITEMAP_MONITOR_GET_FAILURE,
  siteId,
  payload,
})

// sitemap detail
export const getDetailSitemapMonitor = (siteId, sitemapId) => ({
  type: SITE.SITEMAP_MONITOR_DETAIL,
  siteId,
  sitemapId,
})

export const getDetailSitemapMonitorSuccess = (sitemapId, payload) => ({
  type: SITE.SITEMAP_MONITOR_DETAIL_SUCCESS,
  sitemapId,
  payload,
})

export const getDetailSitemapMonitorFailure = (sitemapId, payload) => ({
  type: SITE.SITEMAP_MONITOR_DETAIL_FAILURE,
  sitemapId,
  payload,
})

// sitemap page
export const getPageSitemapMonitor = (siteId, sitemapId) => ({
  type: SITE.SITEMAP_MONITOR_PAGE_GET,
  siteId,
  sitemapId,
})

export const getPageSitemapMonitorSuccess = (sitemapId, payload) => ({
  type: SITE.SITEMAP_MONITOR_PAGE_GET_SUCCESS,
  sitemapId,
  payload,
})

export const getPageSitemapMonitorFailure = (sitemapId, payload) => ({
  type: SITE.SITEMAP_MONITOR_PAGE_GET_FAILURE,
  sitemapId,
  payload,
})

// sitemap page detail
export const getPageDetailSitemapMonitor = (siteId, sitemapId, sitemapPageId) => ({
  type: SITE.SITEMAP_MONITOR_PAGE_DETAIL,
  sitemapPageId,
  siteId,
  sitemapId,
})

export const getPageDetailSitemapMonitorSuccess = (sitemapPageId, payload) => ({
  type: SITE.SITEMAP_MONITOR_PAGE_DETAIL_SUCCESS,
  sitemapPageId,
  payload,
})

export const getPageDetailSitemapMonitorFailure = (sitemapPageId, payload) => ({
  type: SITE.SITEMAP_MONITOR_PAGE_DETAIL_FAILURE,
  sitemapPageId,
  payload,
})

// sitemap history
export const getPageHistorySitemapMonitor = (siteId, sitemapId, sitemapPageId) => ({
  type: SITE.SITEMAP_MONITOR_PAGE_HISTORY_GET,
  sitemapPageId,
  siteId,
  sitemapId,
})

export const getPageHistorySitemapMonitorSuccess = (sitemapPageId, payload) => ({
  type: SITE.SITEMAP_MONITOR_PAGE_HISTORY_GET_SUCCESS,
  sitemapPageId,
  payload,
})

export const getPageHistorySitemapMonitorFailure = (sitemapPageId, payload) => ({
  type: SITE.SITEMAP_MONITOR_PAGE_HISTORY_GET_FAILURE,
  sitemapPageId,
  payload,
})

// create sitemap
export const createSitemapMonitor = (siteId, data, callback) => ({
  type: SITE.SITEMAP_MONITOR_CREATE,
  data,
  callback,
  siteId,
})

export const createSitemapMonitorSuccess = (siteId, payload) => ({
  type: SITE.SITEMAP_MONITOR_CREATE_SUCCESS,
  siteId,
  payload,
})

export const createSitemapMonitorFailure = (siteId, payload) => ({
  type: SITE.SITEMAP_MONITOR_CREATE_FAILURE,
  siteId,
  payload,
})

// update sitemap
export const updateSitemapMonitor = (siteId, sitemapId, data, callback) => ({
  type: SITE.SITEMAP_MONITOR_UPDATE,
  data,
  callback,
  sitemapId,
  siteId,
})

export const updateSitemapMonitorSuccess = (sitemapId, payload) => ({
  type: SITE.SITEMAP_MONITOR_UPDATE_SUCCESS,
  sitemapId,
  payload,
})

export const updateSitemapMonitorFailure = (sitemapId, payload) => ({
  type: SITE.SITEMAP_MONITOR_UPDATE_FAILURE,
  sitemapId,
  payload,
})

// delete sitemap
export const deleteSitemapMonitor = (siteId, sitemapId, callback) => ({
  type: SITE.SITEMAP_MONITOR_DELETE,
  sitemapId,
  callback,
  siteId,
})

export const deleteSitemapMonitorSuccess = (sitemapId, payload) => ({
  type: SITE.SITEMAP_MONITOR_DELETE_SUCCESS,
  sitemapId,
  payload,
})

export const deleteSitemapMonitorFailure = (sitemapId, payload) => ({
  type: SITE.SITEMAP_MONITOR_DELETE_FAILURE,
  sitemapId,
  payload,
})

// Page tracking
export const getPageTrackingList = (siteId, search, page, limit, sort, filter) => ({
  type: SITE.PAGE_TRACKING_LIST,
  siteId,
  search,
  page,
  limit,
  filter,
  sort,
})

export const getPageTrackingListSuccess = (siteId, payload) => ({
  type: SITE.PAGE_TRACKING_LIST_SUCCESS,
  siteId,
  payload,
})

export const getPageTrackingListFailure = (siteId, payload) => ({
  type: SITE.PAGE_TRACKING_LIST_FAILURE,
  siteId,
  payload,
})

// page tracking detail
export const getPageTrackingDetail = (siteId, pageTrackingId) => ({
  type: SITE.PAGE_TRACKING_DETAIL,
  siteId,
  pageTrackingId,
})

export const getPageTrackingDetailSuccess = (siteId, pageTrackingId, payload) => ({
  type: SITE.PAGE_TRACKING_DETAIL_SUCCESS,
  siteId,
  pageTrackingId,
  payload,
})

export const getPageTrackingDetailFailure = (siteId, pageTrackingId, payload) => ({
  type: SITE.PAGE_TRACKING_DETAIL_FAILURE,
  siteId,
  pageTrackingId,
  payload,
})

// get reports
export const getPageTrackingReport = (siteId, pageTrackingId, reportId) => ({
  type: SITE.PAGE_TRACKING_REPORT,
  siteId,
  pageTrackingId,
  reportId,
})

export const getPageTrackingReportSuccess = (siteId, pageTrackingId, payload) => ({
  type: SITE.PAGE_TRACKING_REPORT_SUCCESS,
  siteId,
  pageTrackingId,
  payload,
})

export const getPageTrackingReportFailure = (siteId, pageTrackingId, payload) => ({
  type: SITE.PAGE_TRACKING_REPORT_FAILURE,
  siteId,
  pageTrackingId,
  payload,
})

// get reports
export const getPageTrackingReportHistory = (siteId, pageTrackingId, reportId) => ({
  type: SITE.PAGE_TRACKING_REPORT_HISTORY,
  siteId,
  pageTrackingId,
  reportId,
})

export const getPageTrackingReportHistorySuccess = (siteId, pageTrackingId, reportId, payload) => ({
  type: SITE.PAGE_TRACKING_REPORT_HISTORY_SUCCESS,
  siteId,
  pageTrackingId,
  reportId,
  payload,
})

export const getPageTrackingReportHistoryFailure = (siteId, pageTrackingId, reportId, payload) => ({
  type: SITE.PAGE_TRACKING_REPORT_HISTORY_FAILURE,
  siteId,
  reportId,
  pageTrackingId,
  payload,
})

// create page tracking
export const createPageTracking = (siteId, data, callback) => ({
  type: SITE.PAGE_TRACKING_CREATE,
  data,
  callback,
  siteId,
})

export const createPageTrackingSuccess = (siteId, payload) => ({
  type: SITE.PAGE_TRACKING_CREATE_SUCCESS,
  siteId,
  payload,
})

export const createPageTrackingFailure = (siteId, payload) => ({
  type: SITE.PAGE_TRACKING_CREATE_FAILURE,
  siteId,
  payload,
})

// Update page tracking
export const updatePageTracking = (siteId, pageTrackingId, data, callback) => ({
  type: SITE.PAGE_TRACKING_UPDATE,
  data,
  pageTrackingId,
  siteId,
  callback,
})

export const updatePageTrackingSuccess = (siteId, payload) => ({
  type: SITE.PAGE_TRACKING_UPDATE_SUCCESS,
  siteId,
})

export const updatePageTrackingFailure = (siteId, payload) => ({
  type: SITE.PAGE_TRACKING_UPDATE_FAILURE,
  siteId,
})

// get reports
export const getPageTrackingReportMore = (siteId, pageTrackingId) => ({
  type: SITE.PAGE_TRACKING_MORE_REPORT,
  siteId,
  pageTrackingId,
})

export const getPageTrackingReportMoreSuccess = (siteId, pageTrackingId, payload) => ({
  type: SITE.PAGE_TRACKING_MORE_REPORT_SUCCESS,
  siteId,
  pageTrackingId,
  payload,
})

export const getPageTrackingReportMoreFailure = (siteId, pageTrackingId, payload) => ({
  type: SITE.PAGE_TRACKING_MORE_REPORT_FAILURE,
  siteId,
  pageTrackingId,
  payload,
})

// Tracking by publishable
export const getPageTrackingChartPublishable = (siteId, filter) => ({
  type: SITE.PAGE_TRACKING_CHART_PUBLISABLE,
  siteId,
  filter,
})

export const getPageTrackingChartPublishableSuccess = (siteId, payload) => ({
  type: SITE.PAGE_TRACKING_CHART_PUBLISABLE_SUCCESS,
  siteId,
  payload,
})

export const getPageTrackingChartPublishableFailure = (siteId, payload) => ({
  type: SITE.PAGE_TRACKING_CHART_PUBLISABLE_FAILURE,
  siteId,
})

// Tracking overall performance
export const getPageTrackingChartHistory = (siteId, filter) => ({
  type: SITE.PAGE_TRACKING_CHART_HISTORY,
  siteId,
  filter,
})

export const getPageTrackingChartHistorySuccess = (siteId, payload) => ({
  type: SITE.PAGE_TRACKING_CHART_HISTORY_SUCCESS,
  siteId,
  payload,
})

export const getPageTrackingChartHistoryFailure = (siteId, payload) => ({
  type: SITE.PAGE_TRACKING_CHART_HISTORY_FAILURE,
  siteId,
  payload,
})
