import { call, debounce, fork, put, select, take } from 'redux-saga/effects'
import { APP } from '@constant/app'
import Cookie from '@lib/cookie'
import { getProfile, getProfileSubscription, getProfileSubscriptionStatus } from '@action/profile'
import { eventChannel } from 'redux-saga'
import moment from '@lib/moment'
import { checkVersion } from '@api/app'
import { setLastVersion } from '@action/app'
import { getLastVersionSelector } from '@service/selectors/app'
import { flushStorage } from '@lib/storage/persisted-storage'

function* loopWatch() {
  return eventChannel(emit => {
    const firstTrigger = setTimeout(() => {
      emit(moment().unix())
    }, 1000)
    const eventWatch = setInterval(() => {
      emit(moment().unix())
    }, 180000)

    return () => {
      clearTimeout(firstTrigger)
      clearInterval(eventWatch)
    }
  })
}

function* watchVersion() {
  const eventLoop = yield call(loopWatch)

  while (true) {
    const data = yield take(eventLoop)
    if (data) {
      const { ok, data: version } = yield call(checkVersion)
      const lastVersion = yield select(getLastVersionSelector)
      if (ok) {
        if (lastVersion == 'NEW_LOAD') {
          Cookie.set('--app-version', version)
        }
        yield put(setLastVersion(version))
      }
    }
  }
}

function* updateAppVersionSaga() {
  const lastVersion = yield select(getLastVersionSelector)
  yield flushStorage()
  Cookie.set('--app-version', lastVersion)
  const cacheList = yield caches.keys()
  for (const cacheName of cacheList) {
    yield caches.delete(cacheName)
  }
  window.location.href = '/'
}

function* init() {
  // handle settings
  const authCookie = Cookie.get('--app-auth-token')
  if (authCookie?.length) {
    yield put(getProfile())
    yield put(getProfileSubscription())
    // yield put(getProfileSubscriptionStatus())
  }

  // fork fetch current
  yield fork(watchVersion)
}

export default function* rootSaga() {
  yield debounce(100, APP.APP.INIT, init)
  yield debounce(10, APP.APP.UPDATE_VERSION, updateAppVersionSaga)
}
