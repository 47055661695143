import { CREDENTIAL } from '@constant/credential'

// list
export const getCredentialList = () => ({
  type: CREDENTIAL.LIST,
})

export const setCredentialList = payload => ({
  type: CREDENTIAL.LIST_SUCCESS,
  payload,
})

// create and update
export const createCredential = (data, callback) => ({
  type: CREDENTIAL.CREATE,
  data,
  callback,
})

export const createCredentialSuccess = payload => ({
  type: CREDENTIAL.CREATE_SUCCESS,
  payload,
})

export const createCredentialFailure = payload => ({
  type: CREDENTIAL.CREATE_FAILURE,
  payload,
})

// delete
export const deleteCredential = (credentialId, callback) => ({
  type: CREDENTIAL.DELETE,
  credentialId,
  callback,
})

export const deleteCredentialSuccess = payload => ({
  type: CREDENTIAL.DELETE_SUCCESS,
  payload,
})

export const deleteCredentialFailure = payload => ({
  type: CREDENTIAL.DELETE_FAILURE,
  payload,
})
