import { Button } from '@style/components/button'
import { Card, CardBody } from '@style/components/card'
import styled from 'styled-components'
import Link from 'next/link'
import { getRoute } from '@hook/router'

const Wrapper = styled.div`
  ${Card} {
    width: 600px;
    max-height: calc(100vh - 100px);
  }
`

const Footer = styled.div`
  display: flex;
  margin-top: 30px;
  ${Button} {
    width: 100%;
    justify-content: center;
  }
`
const Title = styled.h2`
  font-size: 28px;
  margin-top: 0;
  color: ${props => props.theme.colors.dark};
`
export default function SubscriptionExpire({ close }) {
  return (
    <Wrapper>
      <Card>
        <CardBody>
          <Title>Update Payment Method Required</Title>
          <div className="p-4 rounded-lg mt-4 bg-primary bg-opacity-20">
            <p>
              Dear valued customer,
              <br />
              <br />
              We are sorry to inform you that your account payment method needs to be updated. Failure to do so will
              result in the suspension of your account in 14 days.
              <br />
              To prevent any interruption to your account services, please update your payment method immediately. You
              can do this by clicking on the 'Update Payment Method' button below and following the instructions
              provided.
              <br />
              <br />
              Update Payment Method
              <br />
              <br />
              Thank you for your cooperation. <br />
              <br />
              Best regards,
              <br />
              <span className="font-extrabold">Seotisfy Team</span>
            </p>
          </div>
          <button className="close" onClick={close}>
            <i className="bx bx-x"></i>
          </button>
          <Footer>
            <Link href={getRoute('settings.billing')}>
              <Button btnColor="white" onClick={close}>
                <span style={{ marginLeft: 10 }}>Update Payment Now</span>
              </Button>
            </Link>
          </Footer>
        </CardBody>
      </Card>
    </Wrapper>
  )
}
