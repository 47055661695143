export const LOGIN = {
  POST: 'AUTH_LOGIN_POST',
  SUCCESS: 'AUTH_LOGIN_SUCCESS',
  FAILURE: 'AUTH_LOGIN_FAILURE',
}

export const SIGNUP = {
  POST: 'AUTH_SIGNUP_POST',
  SUCCESS: 'AUTH_SIGNUP_SUCCESS',
  FAILURE: 'AUTH_SIGNUP_FAILURE',
}

export const LOGOUT = {
  POST: 'AUTH_LOGOUT_POST',
}

export const FORGOT = {
  POST: 'AUTH_FORGOT_POST',
  POST_SUCCES: 'AUTH_FORGOT_POST_SUCCESS',
  POST_FAILURE: 'AUTH_FORGOT_POST_FAILURE',

  VERIFY: 'AUTH_FORGOT_VERIFY',
  VERIFY_SUCCESS: 'AUTH_FORGOT_VERIFY_SUCCESS',
  VERIFY_FAILURE: 'AUTH_FORGOT_VERIFY_FAILURE',

  RESET_PASSWORD: 'AUTH_FORGOT_RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'AUTH_FORGOT_RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'AUTH_FORGOT_RESET_PASSWORD_FAILURE',
}
