export const CREDENTIAL = {
  LIST: 'CREDENTIAL_LIST',
  LIST_SUCCESS: 'CREDENTIAL_LIST_SUCCESS',
  LIST_FAILURE: 'CREDENTIAL_LIST_FAILURE',

  CREATE: 'CREDENTIAL_CREATE',
  CREATE_SUCCESS: 'CREDENTIAL_CREATE_SUCCESS',
  CREATE_FAILURE: 'CREDENTIAL_CREATE_FAILURE',

  DELETE: 'CREDENTIAL_DELETE',
  DELETE_SUCCESS: 'CREDENTIAL_DELETE_SUCCESS',
  DELETE_FAILURE: 'CREDENTIAL_DELETE_FAILURE',
}
