const lightThemes = {
  sizes: {
    xs: '0.8rem', // 12px
    sm: '0.9rem', // 14px
    md: '1rem', // 16px
    xl: '1.2rem', // 18px,
    xlg: '1.3rem', // 20px;
    xxl: '1.5rem', // 24px
  },
  colors: {
    primary: '#304FFD',
    body: '#F6F6F7',
    white: '#fff',
    dark: '#3F434A',
    darkGrey: '#595F69',
    yellow: '#FBC203',
    purple: `#2A3A8F`,
    grey20: '#989E9D',
    grey25: '#CCCCCC',
    grey30: '#F2F4F6',
    grey40: '#D8D8DD',
    danger: '#DC4057',
    red: '#FD7972',
    lightRed: '#FF2D3B',
    grey15: '#666666',
    grey10: '#979797',
    black: '#000',
    grey9: '#8A9099',
    grey8: '#F8F8F8',
    grey7: '#E8E9EB',
    grey6: '#646464',
    grey5: '#777777',
    grey4: '#F2F2F2',
    grey1: '#F2F4F6',
    greyWhite: '#FAFAFA',
    orange: '#FF965D',
    lightOrange: '#FFF5EF',
    blue: '#304FFD',
    lightBlue: '#E9EDFF',
    green: '#49C96D',
    lightGreen: '#ECFAF0',
    cyan: '#22CCE2',
    lightCyan: '#C8F2F8',
    transparent: 'rgba(0,0,0,0)',
  },
  breakpoints: {
    xxl: '1680px',
    xl: '1440px',
    lg: '1080px',
    md: '980px',
    sm: '768px',
    xs: '550px',
  },
}

export const getColors = type => {
  return lightThemes.colors[type]
}

export const themes = type => {
  switch (type) {
    case 'light':
      return lightThemes
    default:
      return lightThemes
  }
}
