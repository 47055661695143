import { CREDENTIAL } from '@constant/credential'
import { FAILURE, REQUEST, SUCCESS } from '@constant/reducer-status'
import produce from 'immer'

const initialState = {
  all: {
    status: false,
    paginate: {},
    data: [],
  },
  detail: {
    status: false,
    message: null,
  },
  create: {
    status: false,
    message: null,
  },
  delete: {
    status: false,
    message: null,
  },
}

export default produce((draft, action) => {
  switch (action.type) {
    // create
    case CREDENTIAL.CREATE:
      draft.create.status = REQUEST
      draft.create.message = null
      break
    case CREDENTIAL.CREATE_SUCCESS:
      draft.create.status = SUCCESS
      draft.create.message = action.payload.data
      break
    case CREDENTIAL.CREATE_FAILURE:
      draft.create.status = FAILURE
      draft.create.message = action.payload.data
      break
    // lists
    case CREDENTIAL.LIST:
      draft.all.status = REQUEST
      break
    case CREDENTIAL.LIST_SUCCESS:
      draft.all.status = SUCCESS
      draft.all.data = action.payload.data
      draft.all.paginate = action.payload.meta
      break

    case CREDENTIAL.DELETE:
      draft.delete.status = REQUEST
      draft.delete.message = null
      break
    case CREDENTIAL.DELETE_SUCCESS:
      draft.delete.status = SUCCESS
      draft.delete.message = action.payload.data
      break
    case CREDENTIAL.DELETE_FAILURE:
      draft.delete.status = FAILURE
      draft.delete.message = action.payload.data
      break
  }
}, initialState)
