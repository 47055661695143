import { call, fork, put, takeLatest } from 'redux-saga/effects'
import callApi from '@saga/api'
import { getProfile, getProfileSubscription, getProfileSubscriptionStatus, updateProfile } from '@api/profile'
import {
  getProfileSubscriptionFailure,
  getProfileSubscriptionStatusFailure,
  getProfileSubscriptionStatusSuccess,
  getProfileSubscriptionSuccess,
  getProfileSuccess,
} from '@action/profile'
import { PROFILE } from '@constant/profile'
import { updateTestFailure, updateTestSuccess } from '@action/seoTest'
import Router from 'next/router'
import { toast } from 'react-toastify'
import { currentPath } from '@hook/router'
import { openPopup } from '@action/menu'

function* getProfileSaga() {
  if (!window.hasFetchProfile) {
    return 0
  }
  const { ok, data: response } = yield call(callApi, getProfile, {})
  if (ok) {
    window.hasFetchProfile = true
    yield put(getProfileSuccess(response.data))
  }
}

function* updateProfileSaga({ data }) {
  const { ok, data: response } = yield call(callApi, updateProfile, data)
  if (ok) {
    yield put(updateTestSuccess(response))
    toast.success('Profile updated')
    yield fork(getProfileSaga)
    yield Router.reload()
  } else {
    toast.error('Failed update settings')
    yield put(updateTestFailure(response))
  }
}

function* getProfileSubscriptionSaga() {
  const { ok, data: response } = yield call(callApi, getProfileSubscription, {})
  if (ok) {
    yield put(getProfileSubscriptionSuccess(response.data))
  } else {
    yield put(getProfileSubscriptionFailure(response))
  }
}

function* getSubscriptionStatusSaga() {
  ///api/v1/stripe/customer
  const { ok, data: response } = yield call(callApi, getProfileSubscriptionStatus)
  const path = currentPath()
  const whitelistedPath = ['settings.profile', 'settings.billing']
  if (whitelistedPath.indexOf(path?.name) < 0) {
    if (response.data.status !== 'ACTIVE') {
      yield put(openPopup('subscription-expire'))
    }
  }

  if (ok) {
    yield put(getProfileSubscriptionStatusSuccess(response.data))
  } else {
    yield put(getProfileSubscriptionStatusFailure(response))
  }
}

export default function* rootSaga() {
  yield takeLatest(PROFILE.GET_PROFILE, getProfileSaga)
  yield takeLatest(PROFILE.GET_PROFILE_SUBSCRIPTION, getProfileSubscriptionSaga)
  yield takeLatest(PROFILE.UPDATE_PROFILE, updateProfileSaga)
  yield takeLatest(PROFILE.GET_PROFILE_SUBSCRIPTION_STATUS, getSubscriptionStatusSaga)
}
