import { call, debounce, fork, put, select, takeLatest, throttle } from 'redux-saga/effects'
import callApi from '@saga/api'
import {
  bulkDeleteTracker,
  createTracking,
  deleteTracker,
  getTrackingSummary,
  getTrackingTag,
  tracking,
  trackingDetail,
  trackingDetailSummary,
  trackingImport,
  trackingSync,
} from '@api/site'
import { SITE } from '@constant/site'
import {
  createTrackingFailure,
  createTrackinSuccess,
  getSiteTrackingSummaryFailure,
  getSiteTrackingSummarySuccess,
  getTrackingOverviewFailure,
  getTrackingOverviewSuccess,
  getTrackingTagFailure,
  getTrackingTagSuccess,
  importSiteTrackingFailure,
  importSiteTrackingSuccess,
  setSiteTracking,
  setSiteTrackingDetail,
} from '@action/site'
import { toast } from 'react-toastify'
import Router from 'next/router'
import { getRoute } from '@hook/router'
import XLSX from 'xlsx'
import moment from 'moment/moment'
import { isObject } from 'formik'

function* trackingSiteSaga({ siteId, filters }) {
  const extraParams = Object.entries(filters || {})
    .map(([l, r]) => {
      if (isObject(r)) {
        return Object.entries(r)
          .map(([l1, r2]) => [`${l}[${l1}]=${r2?.toString().length > 0 ? r2 : ''}`])
          .join('&')
      }
      return `${l}=${r}`
    })
    .join('&')
  const { ok, data: response } = yield call(callApi, tracking, siteId, extraParams)
  if (ok) {
    yield put(setSiteTracking(response, siteId))
  }
}

function* trackingDetailSiteSaga({ siteId, trackingId, filter }) {
  const { ok, data: response } = yield call(callApi, trackingDetail, siteId, trackingId, {
    start_date: filter.startDate,
    end_date: filter.endDate,
  })
  if (ok) {
    yield put(setSiteTrackingDetail(response, trackingId))
  }
}

function* getTrackingSummarySaga({ siteId, trackingId, filter }) {
  const { ok, data } = yield call(callApi, trackingDetailSummary, siteId, trackingId, {
    start_date: filter.startDate,
    end_date: filter.endDate,
  })

  if (ok) {
    yield put(getSiteTrackingSummarySuccess(data, trackingId))
  } else {
    yield put(getSiteTrackingSummaryFailure(data, trackingId))
  }
}

function* trackingCreateSaga({ data, callback, siteId }) {
  const { ok, data: response } = yield call(callApi, createTracking, siteId, data)
  if (ok) {
    toast.success('Keyword added to tracker')
    yield put(createTrackinSuccess(response))
    yield fork(trackingSiteSaga, { siteId })
    if (callback) {
      yield callback()
    }
  } else {
    const customErrorMessage = Object.entries(response?.data).find(([field]) => field?.includes('expression'))?.[1]
    toast.error(customErrorMessage || 'Failed add keyword to tracker')
    yield put(createTrackingFailure(response))
  }
}

function* deleteTrackerSaga({ siteId, trackerId, callback }) {
  console.log(trackerId)
  const { ok, data: response } = yield call(
    callApi,
    Array.isArray(trackerId) ? bulkDeleteTracker : deleteTracker,
    siteId,
    trackerId
  )
  if (ok) {
    yield toast.success('Tracker has been deleted')
    yield put(createTrackinSuccess(response))
    yield fork(trackingSiteSaga, { siteId })

    if (callback) {
      yield callback()
    }
  } else {
    yield toast.error('Failed to delete tracker')
    yield put(createTrackingFailure(response))
  }
}

function* syncTrackerSaga({ siteId, trackerId }) {
  const { ok } = yield call(callApi, trackingSync, siteId, trackerId)
  if (ok) {
    toast.success('Site tracking sync dispatched')
  } else {
    toast.error('Failed to sync tracker')
  }
}

function* importJsonSaga({ siteId, data, callback }) {
  const { ok, data: response } = yield call(callApi, trackingImport, siteId, data)
  if (ok) {
    yield put(importSiteTrackingSuccess(siteId, response))
    toast.success('Keyword imported')
    yield fork(trackingSiteSaga, { siteId })
    yield Router.push(getRoute('site.tracking.index', { id: siteId }))
    if (callback) {
      yield callback()
    }
  } else {
    yield put(importSiteTrackingFailure(siteId, response))
    yield fork(trackingSiteSaga, { siteId })
    toast.success('Failed import keyword')
  }
}

function* getTrackingTagSaga({ siteId }) {
  const { ok, data: response } = yield call(callApi, getTrackingTag, siteId)
  //getTrackingTag
  if (ok) {
    yield put(getTrackingTagSuccess(siteId, response?.data || []))
  } else {
    yield put(getTrackingTagFailure(siteId, response?.data || 'Failed to request'))
  }
}

function* exportTrackingSaga({ siteId }) {
  const tagsData = yield select(state => state.site.trackingTag[siteId])
  const siteDetail = yield select(state => state.site.detail[siteId])
  try {
    if (tagsData.data) {
      const workSheetData = tagsData.data.map(c => ({
        Tag: c.tag.tag,
        'AVG.POS': c.data?.[0]?.avg_position || 0,
        TRAFFIC: c.data?.[0]?.avg_click || 0,
        KEYWORDS: c.data?.[0]?.total_keyword || 0,
        '#1-3': c.data?.[0]?.total_keyword_between_1_3 || 0,
        '#4-10': c.data?.[0]?.total_keyword_between_4_10 || 0,
        '#11-50': c.data?.[0]?.total_keyword_between_11_50 || 0,
        '#51-100': c.data?.[0]?.total_keyword_between_51_100 || 0,
        '#100+': c.data?.[0]?.total_keyword_between_100_more || 0,
        'Last Update': moment(c.data?.updated_at || new Date()).format('YYYY-MM-DD hh:mm'),
      }))

      const worksheet = XLSX.utils.json_to_sheet(workSheetData, {})
      const workBook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workBook, worksheet, 'Tags')
      XLSX.writeFile(workBook, `${moment().format('YYYYMMDDhhmmss')}-${siteDetail.data.slug || 'seotisfy'}.xlsx`)
      toast.success('Tag Exported', {
        autoClose: 2000,
      })
    } else {
      toast.error('Failed export because no data found')
    }
  } catch (e) {
    toast.error(e?.message || 'Failed export')
  }
}

function* getTrackingOverviewSaga({ siteId, filters }) {
  const extraParams = Object.entries(filters || {})
    .map(([l, r]) => {
      if (isObject(r)) {
        return Object.entries(r)
          .map(([l1, r2]) => [`${l}[${l1}]=${r2?.toString().length > 0 ? r2 : ''}`])
          .join('&')
      }
      return `${l}=${r}`
    })
    .join('&')
  const { ok, data: response } = yield call(callApi, getTrackingSummary, siteId, extraParams)
  if (ok) {
    yield put(getTrackingOverviewSuccess(siteId, response?.data || []))
  } else {
    yield put(getTrackingOverviewFailure(siteId, response?.data || 'Failed to request'))
  }
}

export default function* rootSaga() {
  yield debounce(100, SITE.TRACKING, trackingSiteSaga)
  yield debounce(10, SITE.TRACKING_DETAIL, trackingDetailSiteSaga)
  yield debounce(10, SITE.TRACKING_CREATE, trackingCreateSaga)
  yield debounce(10, SITE.TRACKING_SUMMARY, getTrackingSummarySaga)
  yield debounce(10, SITE.TRACKING_DELETE, deleteTrackerSaga)
  yield debounce(10, SITE.TRACKING_SYNC, syncTrackerSaga)
  yield debounce(10, SITE.TRACKING_TAG_GET, getTrackingTagSaga)
  yield throttle(10, SITE.TRACKING_OVERVIEW_GET, getTrackingOverviewSaga)
  yield takeLatest(SITE.TRACKING_TAG_EXPORT, exportTrackingSaga)
  yield takeLatest(SITE.TRACKING_IMPORT, importJsonSaga)
}
