import axios from 'axios'
import axiosRetry from 'axios-retry'
import { create } from 'apisauce'
import Cookie from '@lib/cookie'
import * as interceptors from '@lib/api/interceptors'

/**
 * Web api confgiration
 */
export const webApi = ({ auth, req, res, asset, self } = {}) => {
  const authCookie = (req && res ? new Cookie({ req, res }) : Cookie).get('--app-auth-token')
  const baseApi = axios.create({
    baseURL: asset || self ? '' : process.env.apiBaseUrl,
    responseType: asset ? 'blob' : false,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      ...(auth && authCookie ? { Authorization: `Bearer ${authCookie}` } : {}),
    },
  })
  baseApi.interceptors.request.use(
    config => {
      config = interceptors.uploadRequest(baseApi, config)
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )
  axiosRetry(baseApi, { retryDelay: axiosRetry.exponentialDelay })
  return create({
    axiosInstance: baseApi,
    timeout: 20000,
  })
}
