import { call, debounce, put, takeLatest } from 'redux-saga/effects'
import callApi from '@saga/api'
import { createFilter, listFilters, getTypeFilter } from '@api/site'
import { SITE } from '@constant/site'
import { setSiteFilterList } from '@action/site'

function* listFilterSaga() {
  const { ok, data: response } = yield call(callApi, listFilters)
  if (ok) {
    yield put(setSiteFilterList(response))
  }
}

export default function* rootSaga() {
  yield debounce(50, SITE.FILTER_LIST, listFilterSaga)
}
