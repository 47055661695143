import { FAILURE, REQUEST, SUCCESS } from '@/constants/reducer-status'
import { APP } from '@constant/app'
import dates from '@constant/date'
import { SITE } from '@constant/site'
import produce, { current } from 'immer'
import moment from '@lib/moment'
import { SEOTEST } from '@constant/seoTest'
import sampleState from './sample-state'
import initialState from './initial-state'
import { REPORT } from '@constant/report'

export default produce((draft, action) => {
  const currentDraft = current(draft)
  switch (action.type) {
    case APP.SITE.INIT:
      if (action.siteId) {
        if (!draft.trackingQuery[action.siteId]) {
          draft.trackingQuery[action.siteId] = sampleState.trackingQuery
        }
        if (!draft.compareQuery[action.siteId]) {
          draft.compareQuery[action.siteId] = sampleState.compareQuery
        }
      }
      break
    // lists
    case SITE.LIST:
      draft.all.status = REQUEST
      break
    case SITE.LIST_SUCCESS:
      draft.all.status = SUCCESS
      draft.all.data = action.payload.data
      draft.all.paginate = action.payload.meta
      break

    // detail
    case SITE.DETAIL:
      draft.detail[action.siteId] = {
        ...(current(draft)?.detail?.[action.siteId] ?? {}),
        status: REQUEST,
      }
      break
    case SITE.DETAIL_SUCCESS:
      draft.detail[action.payload?.data?.id || 0] = {
        status: SUCCESS,
        data: action.payload.data,
      }
      break
    // create
    case SITE.CREATE_SITE:
      draft.create.status = REQUEST
      draft.create.message = null
      break
    // create success
    case SITE.CREATE_SITE_SUCCESS:
      draft.create.status = SUCCESS
      draft.create.message = action.payload.data
      break
    // create failure
    case SITE.CREATE_SITE_FAILURE:
      draft.create.status = FAILURE
      draft.create.message = action.payload.data
      break
    // overviews
    case SITE.OVERVIEW:
      if (!draft.overview[action.siteId]) {
        draft.overview[action.siteId] = {
          status: REQUEST,
          data: {},
        }
      }
      if (!draft.compareOverview[action.siteId]) {
        draft.compareOverview[action.siteId] = {
          status: REQUEST,
          data: {},
        }
      }
      draft.overview[action.siteId].status = REQUEST
      break
    case SITE.OVERVIEW_SUCCESS:
      draft.overview[action.siteId].status = SUCCESS
      draft.overview[action.siteId].data[action.payload.data.dimension?.[0]] = action.payload.data.data
      break
    // filters
    case SITE.TRACKING:
      draft.tracking[action.siteId] = {
        status: REQUEST,
        data: draft.tracking[action.siteId]?.data || [],
        paginate: draft.tracking[action.siteId]?.paginate || {},
      }
      break
    case SITE.TRACKING_SUCCESS:
      draft.tracking[action.siteId] = {
        status: SUCCESS,
        data: action.payload.data || [],
        paginate: action.payload.meta || {},
      }
      break

    // import tracking
    case SITE.TRACKING_IMPORT:
      draft.trackingImport[action.siteId] = {
        status: REQUEST,
        data: draft.trackingImport[action.siteId]?.data || [],
      }
      break
    case SITE.TRACKING_IMPORT_SUCCESS:
      draft.trackingImport[action.siteId] = {
        status: SUCCESS,
        data: action.payload.data || [],
      }
      break
    case SITE.TRACKING_IMPORT_FAILURE:
      draft.trackingImport[action.siteId] = {
        status: FAILURE,
        data: action.payload.data || [],
      }
      break

    // filters detail
    case SITE.TRACKING_DETAIL:
      draft.trackingDetail[action.trackingId] = {
        ...(draft.trackingDetail[action.trackingId] || {}),
        status: REQUEST,
        filter: action.filter,
      }
      break
    case SITE.TRACKING_DETAIL_SUCCESS:
      draft.trackingDetail[action.trackingId] = {
        ...(draft.trackingDetail[action.trackingId] || {}),
        status: SUCCESS,
        data: action.payload.data,
      }
      break
    case SITE.TRACKING_DETAIL_FAILURE:
      draft.trackingDetail[action.trackingId] = {
        ...(draft.trackingDetail[action.trackingId] || {}),
        status: FAILURE,
        data: action.payload.data,
      }
      break

    // filters detail summary
    case SITE.TRACKING_SUMMARY:
      draft.trackingSummary[action.trackingId] = {
        status: REQUEST,
        filter: action.filter,
        ...(draft.trackingDetail[action.trackingId] || {}),
      }
      break
    case SITE.TRACKING_SUMMARY_SUCCESS:
      draft.trackingSummary[action.trackingId] = {
        ...(draft.trackingDetail[action.trackingId] || {}),
        status: SUCCESS,
        data: action.payload.data,
      }
      break
    case SITE.TRACKING_SUMMARY_FAILURE:
      draft.trackingSummary[action.trackingId] = {
        ...(draft.trackingDetail[action.trackingId] || {}),
        status: FAILURE,
        data: action.payload.data,
      }
      break

    case SITE.TRACKING_CREATE:
      draft.trackingCreate.status = REQUEST
      draft.message = null
      break

    case SITE.TRACKING_CREATE_SUCCESS:
      draft.trackingCreate.status = SUCCESS
      draft.message = action?.payload?.data || 'Tracker Created Successfully'
      break
    case SITE.TRACKING_CREATE_FAILURE:
      draft.trackingCreate.status = FAILURE
      draft.message = action.payload.data
      break

    // filters
    case SITE.FILTER_LIST:
      draft.filters.status = REQUEST
      break
    case SITE.FILTER_LIST_SUCCESS:
      draft.filters.status = SUCCESS
      draft.filters.data = action.payload.data
      break
    // filters
    case SITE.FILTER_CREATE:
      draft.filtersCreate.status = REQUEST
      break
    case SITE.FILTER_CREATE_SUCCESS:
      draft.filtersCreate.status = SUCCESS
      break
    // overview filter
    case SITE.SET_OVERVIEW_FILTER:
      draft.overviewFilter[action.filter] = action.value
      break
    // manual sync
    case SITE.TRACKING_SYNC:
      draft.trackingSync.status = REQUEST
      draft.trackingSync.messafe = null
      break
    case SITE.TRACKING_SYNC_SUCCESS:
      draft.trackingSync.status = SUCCESS
      draft.trackingSync.messafe = action.payload.data
      break
    case SITE.TRACKING_SYNC_FAILURE:
      draft.trackingSync.status = FAILURE
      draft.trackingSync.messafe = action.payload.data
      break
    // get available sites
    case SITE.GET_AVAILABLE_SITE:
      draft.availableSites.data = []
      draft.availableSites.status = REQUEST
      break
    case SITE.GET_AVAILABLE_SITE_SUCCESS:
      draft.availableSites.data = action.payload.data
      draft.availableSites.status = SUCCESS
      break
    case SITE.GET_AVAILABLE_SITE_FAILURE:
      draft.availableSites.data = []
      draft.availableSites.status = FAILURE
      break
    case SITE.SET_ACTIVE:
      draft = initialState
      break
    // set tracking summary date
    case SITE.SET_TRACKING_SUMMARY_DATE:
      /**
       * data
       * * endDate
       * * startDate
       * * type
       */
      draft.trackingQuery[action.siteId].date = action.data
      draft.compareQuery[action.siteId] = { ...sampleState.compareQuery }

      if (action.compare) {
        draft.compareQuery[action.siteId] = { ...sampleState.compareQuery }
        draft.compareQuery[action.siteId].date = action.compare || null
      }

      break
    /**
     * data
     * * type
     */
    case SITE.SET_TRACKING_SUMMARY_TYPE:
      draft.trackingQuery[action.siteId].type = action.data.type || 'web'
      draft.compareQuery[action.siteId].type = null

      draft.compareQuery[action.siteId] = { ...sampleState.compareQuery }

      if (!dates.find(c => c.value == currentDraft.trackingQuery[action.siteId].date.type)) {
        draft.trackingQuery[action.siteId].date = { ...sampleState.trackingQuery.date }
      }

      if (action.data.compare) {
        draft.compareQuery[action.siteId] = { ...sampleState.compareQuery }
        draft.compareQuery[action.siteId].type = action.data.compare || 'image'
      }
      break

    // set data type
    case SITE.SET_TRACKING_DATA_TYPE:
      draft.trackingQuery[action.siteId].state = action.data.state || 'final'
      draft.compareQuery[action.siteId].state = action.data.state || 'final'

      break
    /**
     * data
     * * type
     * * operator
     * * expression
     */
    case SITE.SET_TRACKING_SUMMARY_QUERY:
      draft.trackingQuery[action.siteId].queries[action.data.type] = {
        operator: action.data.operator || 'contains',
        expression: action.data.expression,
      }

      if (action.compare) {
        if (!dates.find(c => c.value == currentDraft.trackingQuery[action.siteId].date.type)) {
          draft.trackingQuery[action.siteId].date = { ...sampleState.trackingQuery.date }
        }

        draft.compareQuery[action.siteId] = { ...sampleState.compareQuery }

        draft.compareQuery[action.siteId].queries = { ...sampleState.compareQuery }

        draft.compareQuery[action.siteId].queries[action.data.type] = {
          operator: action.compare.operator || 'contains',
          expression: action.compare.expression,
        }
      }

      break
    /**
     * Set compare date
     */
    // case site.OVERVIEW_COMPARE:
    //   console.log('RTX', draft.compareOverview[action.siteId])

    //   draft.compareOverview[action.siteId].status = REQUEST
    //   break
    case SITE.OVERVIEW_COMPARE_SUCCESS:
      draft.compareOverview[action.siteId].status = SUCCESS
      draft.compareOverview[action.siteId].data[action.payload.data.dimension?.[0]] = action.payload.data.data
      break

    // seo test list
    case SEOTEST.LIST:
      draft.seoTestList[action.siteId] = {
        ...sampleState.seoTestList,
        ...(draft.seoTestList[action.siteId] || {}),
      }
      break
    case SEOTEST.LIST_SUCCESS:
      draft.seoTestList[action.siteId] = {
        status: SUCCESS,
        data: action.payload,
      }
      break
    case SEOTEST.LIST_FAILURE:
      draft.seoTestList[action.siteId] = {
        status: FAILURE,
        data: [],
      }
      break

    // seo test detail
    case SEOTEST.DETAIL_TEST:
      draft.seoTestDetail[action.seoTestId] = {
        ...sampleState.seoTestDetail,
        ...(draft.seoTestDetail[action.seoTestId] || {}),
      }
      break
    case SEOTEST.DETAIL_TEST_SUCCESS:
      draft.seoTestDetail[action.seoTestId] = {
        status: SUCCESS,
        data: action.payload,
      }
      break
    case SEOTEST.DETAIL_TEST_FAILURE:
      draft.seoTestDetail[action.seoTestId] = {
        status: FAILURE,
        data: {},
      }
      break

    // seo test create
    case SEOTEST.CREATE:
      draft.seoTestCreate[action.siteId] = {
        ...sampleState.seoTestCreate,
        ...(draft.seoTestCreate[action.siteId] || {}),
      }
      break
    case SEOTEST.CREATE_SUCCESS:
      draft.seoTestCreate[action.siteId] = {
        status: SUCCESS,
        data: action.payload,
        errors: {},
      }
      break
    case SEOTEST.CREATE_FAILURE:
      draft.seoTestCreate[action.siteId] = {
        status: FAILURE,
        data: {},
        errors: action.payload,
      }
      break

    // seo test create
    case SEOTEST.UPDATE:
      draft.seoTestUpdate[action.seoTestId] = {
        ...sampleState.seoTestUpdate,
        ...(draft.seoTestUpdate[action.seoTestId] || {}),
      }
      break
    case SEOTEST.UPDATE_SUCCESS:
      draft.seoTestUpdate[action.seoTestId] = {
        status: SUCCESS,
        data: action.payload,
        errors: {},
      }
      break
    case SEOTEST.UPDATE_FAILURE:
      draft.seoTestUpdate[action.seoTestId] = {
        status: FAILURE,
        data: {},
        errors: action.payload,
      }
      break

    // seo test summary
    case SEOTEST.DETAIL_SUMMARY_TEST:
      draft.seoTestSummary[action.seoTestId] = {
        ...sampleState.seoTestSummary,
        ...(draft.seoTestSummary[action.seoTestId] || {}),
      }
      break
    case SEOTEST.DETAIL_SUMMARY_TEST_SUCCESS:
      draft.seoTestSummary[action.seoTestId] = {
        status: SUCCESS,
        data: action.payload,
      }
      break
    case SEOTEST.DETAIL_SUMMARY_TEST_FAILURE:
      draft.seoTestSummary[action.seoTestId] = {
        status: FAILURE,
        data: {},
      }
      break
    // seo test history
    case SEOTEST.DETAIL_HISTORY_TEST:
      draft.seoTestHistory[action.seoTestId] = {
        ...sampleState.seoTestHistory,
        ...(draft.seoTestHistory[action.seoTestId] || {}),
      }
      break
    case SEOTEST.DETAIL_HISTORY_TEST_SUCCESS:
      draft.seoTestHistory[action.seoTestId] = {
        status: SUCCESS,
        data: action.payload,
      }
      break
    case SEOTEST.DETAIL_HISTORY_TEST_FAILURE:
      draft.seoTestHistory[action.seoTestId] = {
        status: FAILURE,
        data: {},
      }
      break

    // seo test import
    case SEOTEST.IMPORT_TEST:
      draft.seoTestImport[action.siteId] = {
        ...sampleState.seoTestImport,
        ...(draft.seoTestImport[action.siteId] || {}),
      }
      break
    case SEOTEST.IMPORT_TEST_SUCCESS:
      draft.seoTestImport[action.siteId] = {
        status: SUCCESS,
        data: action.payload,
      }
      break
    case SEOTEST.IMPORT_TEST_FAILURE:
      draft.seoTestImport[action.siteId] = {
        status: FAILURE,
        data: {},
      }
      break
    case SITE.GET_PAGE_QUERY:
      draft.missingKeywordPage[action.pageId] = sampleState.missingKeywordPage
      break

    case SITE.GET_PAGE_QUERY_SUCCESS:
      draft.missingKeywordPage[action.pageId] = {
        status: SUCCESS,
        data: action.payload || [],
        errors: {},
      }
      break
    case SITE.GET_PAGE_QUERY_FAILURE:
      draft.missingKeywordPage[action.pageId] = {
        status: FAILURE,
        data: [],
        errors: action.payload,
      }
      break

    // missing keyword counter total
    case SITE.GET_QUERY_COUNTER_SUCCESS:
      draft.missingKeywordQuery[action.cacheName] = {
        total: action.payload,
        timestamp: moment().toISOString(),
      }
      break

    // reset query missing counter
    case SITE.RESET_QUERY_COUNTER_SUCCESS:
      for (const cacheName in currentDraft.missingKeywordQuery) {
        if (cacheName.includes(`${action.pageName}:`)) {
          delete draft.missingKeywordQuery[cacheName]
        }
      }
      break

    // missing keyword page
    case SITE.GET_MISSING_KEYWORD_PAGE:
      draft.misingKeywordPageData[`${action.siteId}:${action.page ?? 'ALL'}`] = {
        ...sampleState.misingKeywordPageData,
        ...(draft.misingKeywordPageData[`${action.siteId}:${action.page ?? 'ALL'}`] || {}),
        status: REQUEST,
      }
      break
    case SITE.GET_MISSING_KEYWORD_PAGE_SUCCESS:
      draft.misingKeywordPageData[`${action.siteId}:${action.page ?? 'ALL'}`] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break

    case SITE.GET_MISSING_KEYWORD_PAGE_FAILURE:
      draft.misingKeywordPageData[`${action.siteId}:${action.page ?? 'ALL'}`] = {
        status: FAILURE,
        errors: action.payload,
        data: [],
      }
      break

    case SITE.GET_MISSING_KEYWORD_PAGE_LIST:
      draft.missingKeywordPageList[action.siteId] = {
        ...sampleState.missingKeywordPageList,
        ...(draft.missingKeywordPageList[action.siteId] || {}),
        status: REQUEST,
      }
      break
    case SITE.GET_MISSING_KEYWORD_PAGE_LIST_SUCCESS:
      draft.missingKeywordPageList[action.siteId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break

    case SITE.GET_MISSING_KEYWORD_PAGE_LIST_FAILURE:
      draft.missingKeywordPageList[action.siteId] = {
        status: FAILURE,
        errors: action.payload,
        data: [],
      }
      break

    // handle tracking tags
    case SITE.TRACKING_TAG_GET:
      draft.trackingTag[action.siteId] = {
        ...sampleState.trackingTag,
        ...(draft.trackingTag[action.siteId] || {}),
        status: REQUEST,
      }
      break

    case SITE.TRACKING_TAG_GET_SUCCESS:
      draft.trackingTag[action.siteId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.TRACKING_TAG_GET_FAILURE:
      draft.trackingTag[action.siteId] = {
        status: FAILURE,
        errors: action.payload,
        data: [],
      }

    case SITE.TRACKING_OVERVIEW_GET:
      draft.trackingOverview[action.siteId] = {
        ...sampleState.trackingOverview,
        ...(draft.trackingOverview[action.siteId] || {}),
        status: REQUEST,
      }
      break

    case SITE.TRACKING_OVERVIEW_GET_SUCCESS:
      draft.trackingOverview[action.siteId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.TRACKING_OVERVIEW_GET_FAILURE:
      draft.trackingOverview[action.siteId] = {
        status: FAILURE,
        errors: action.payload,
        data: [],
      }
      break

    // mentioned in meta
    case SITE.GET_MENTIONED_IN_META:
      draft.mentionedInMeta[action.siteId] = {
        ...sampleState.mentionedInMeta,
        ...(draft.mentionedInMeta[action.siteId] || {}),
        status: REQUEST,
      }
      break

    case SITE.GET_MENTIONED_IN_META_SUCCESS:
      draft.mentionedInMeta[action.siteId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.GET_MENTIONED_IN_META_FAILURE:
      draft.mentionedInMeta[action.siteId] = {
        status: FAILURE,
        errors: action.payload,
        data: [],
      }
      break

    // mentioned in meta
    case SITE.LOG_CREATE:
      draft.changelogCreate[action.siteId] = {
        ...sampleState.changelogCreate,
        ...(draft.changelogCreate[action.siteId] || {}),
        status: REQUEST,
      }
      break

    case SITE.LOG_CREATE_SUCCESS:
      draft.changelogCreate[action.siteId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.LOG_CREATE_FAILURE:
      draft.changelogCreate[action.siteId] = {
        status: FAILURE,
        errors: action.payload,
        data: [],
      }
      break

    // get changelog
    case SITE.LOG_GET:
      draft.changelog[action.siteId] = {
        ...sampleState.changelog,
        ...(draft.changelog[action.siteId] || {}),
        status: REQUEST,
      }
      break

    case SITE.LOG_GET_SUCCESS:
      draft.changelog[action.siteId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.LOG_GET_FAILURE:
      draft.changelog[action.siteId] = {
        status: FAILURE,
        errors: action.payload,
        data: [],
      }
      break

    // changelog update
    case SITE.LOG_UPDATE:
      draft.changelogUpdate[action.siteId] = {
        ...sampleState.changelogUpdate,
        ...(draft.changelogUpdate[action.siteId] || {}),
        status: REQUEST,
      }
      break

    case SITE.LOG_UPDATE_SUCCESS:
      draft.changelogUpdate[action.siteId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.LOG_UPDATE_FAILURE:
      draft.changelogUpdate[action.siteId] = {
        status: FAILURE,
        errors: action.payload.errors || {},
        data: [],
      }
      break

    // delete changelog
    case SITE.LOG_DELETE:
      draft.changelogDelete[action.siteId] = {
        ...sampleState.changelogDelete,
        ...(draft.changelogDelete[action.siteId] || {}),
        status: REQUEST,
      }
      break

    case SITE.LOG_DELETE_SUCCESS:
      draft.changelogDelete[action.siteId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.LOG_DELETE_FAILURE:
      draft.changelogDelete[action.siteId] = {
        status: FAILURE,
        errors: action.payload.errors || {},
        data: [],
      }
      break

    // meta
    case SITE.META_GET:
      draft.siteMeta[action.siteId] = {
        ...sampleState.siteMeta,
        ...(draft.siteMeta[action.siteId] || {}),
        status: REQUEST,
      }
      break

    case SITE.META_GET_SUCCESS:
      draft.siteMeta[action.siteId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.META_GET_FAILURE:
      draft.siteMeta[action.siteId] = {
        status: FAILURE,
        errors: action?.payload?.errors || {},
        data: [],
      }
      break

    // list of sitemap
    case SITE.SITEMAP_MONITOR_GET:
      draft.sitemapMonitoringList[action.siteId] = {
        ...sampleState.sitemapMonitoringList,
        ...(draft.sitemapMonitoringList[action.siteId] || {}),
        status: REQUEST,
      }
      break

    case SITE.SITEMAP_MONITOR_GET_SUCCESS:
      draft.sitemapMonitoringList[action.siteId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.SITEMAP_MONITOR_GET_FAILURE:
      draft.sitemapMonitoringList[action.siteId] = {
        status: FAILURE,
        errors: action?.payload?.errors || {},
        data: [],
      }
      break

    // detail sitemap
    case SITE.SITEMAP_MONITOR_DETAIL:
      draft.sitemapMonitoringDetail[action.sitemapId] = {
        ...sampleState.sitemapMonitoringDetail,
        ...(draft.sitemapMonitoringDetail[action.sitemapId] || {}),
        status: REQUEST,
      }
      break

    case SITE.SITEMAP_MONITOR_DETAIL_SUCCESS:
      draft.sitemapMonitoringDetail[action.sitemapId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.SITEMAP_MONITOR_DETAIL_FAILURE:
      draft.sitemapMonitoringDetail[action.sitemapId] = {
        status: FAILURE,
        errors: action?.payload?.errors || {},
        data: [],
      }
      break

    // sitemap list of pages
    case SITE.SITEMAP_MONITOR_PAGE_GET:
      draft.sitemapMonitoringPage[action.sitemapId] = {
        ...sampleState.sitemapMonitoringPage,
        ...(draft.sitemapMonitoringPage[action.sitemapId] || {}),
        status: REQUEST,
      }
      break

    case SITE.SITEMAP_MONITOR_PAGE_GET_SUCCESS:
      draft.sitemapMonitoringPage[action.sitemapId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.SITEMAP_MONITOR_PAGE_GET_FAILURE:
      draft.sitemapMonitoringPage[action.sitemapId] = {
        status: FAILURE,
        errors: action?.payload?.errors || {},
        data: [],
      }
      break

    // sitemap page detail
    case SITE.SITEMAP_MONITOR_PAGE_DETAIL:
      draft.sitemapMonitoringPageDetail[action.sitemapPageId] = {
        ...sampleState.sitemapMonitoringPageDetail,
        ...(draft.sitemapMonitoringPageDetail[action.sitemapPageId] || {}),
        status: REQUEST,
      }
      break

    case SITE.SITEMAP_MONITOR_PAGE_DETAIL_SUCCESS:
      draft.sitemapMonitoringPageDetail[action.sitemapPageId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.SITEMAP_MONITOR_PAGE_DETAIL_FAILURE:
      draft.sitemapMonitoringPageDetail[action.sitemapPageId] = {
        status: FAILURE,
        errors: action?.payload?.errors || {},
        data: [],
      }
      break

    // sitemap page history
    case SITE.SITEMAP_MONITOR_PAGE_HISTORY_GET:
      draft.sitemapMonitoringPageHistory[action.sitemapPageId] = {
        ...sampleState.sitemapMonitoringPageHistory,
        ...(draft.sitemapMonitoringPageHistory[action.sitemapPageId] || {}),
        status: REQUEST,
      }
      break

    case SITE.SITEMAP_MONITOR_PAGE_HISTORY_GET_SUCCESS:
      draft.sitemapMonitoringPageHistory[action.sitemapPageId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.SITEMAP_MONITOR_PAGE_HISTORY_GET_FAILURE:
      draft.sitemapMonitoringPageHistory[action.sitemapPageId] = {
        status: FAILURE,
        errors: action?.payload?.errors || {},
        data: [],
      }
      break

    // create
    case SITE.SITEMAP_MONITOR_CREATE:
      draft.sitemapMonitoringCreate[action.siteId] = {
        ...sampleState.sitemapMonitoringCreate,
        ...(draft.sitemapMonitoringCreate[action.siteId] || {}),
        status: REQUEST,
      }
      break

    case SITE.SITEMAP_MONITOR_CREATE_SUCCESS:
      draft.sitemapMonitoringCreate[action.siteId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.SITEMAP_MONITOR_CREATE_FAILURE:
      draft.sitemapMonitoringCreate[action.siteId] = {
        status: FAILURE,
        errors: action?.payload?.errors || {},
        data: [],
      }
      break

    // update
    case SITE.SITEMAP_MONITOR_UPDATE:
      draft.sitemapMonitoringUpdate[action.sitemapPageId] = {
        ...sampleState.sitemapMonitoringUpdate,
        ...(draft.sitemapMonitoringUpdate[action.sitemapPageId] || {}),
        status: REQUEST,
      }
      break

    case SITE.SITEMAP_MONITOR_UPDATE_SUCCESS:
      draft.sitemapMonitoringUpdate[action.sitemapPageId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.SITEMAP_MONITOR_UPDATE_FAILURE:
      draft.sitemapMonitoringUpdate[action.sitemapPageId] = {
        status: FAILURE,
        errors: action?.payload?.errors || {},
        data: [],
      }
      break

    // delete
    case SITE.SITEMAP_MONITOR_DELETE:
      draft.sitemapMonitoringUpdate[action.sitemapPageId] = {
        ...sampleState.sitemapMonitoringUpdate,
        ...(draft.sitemapMonitoringUpdate[action.sitemapPageId] || {}),
        status: REQUEST,
      }
      break

    case SITE.SITEMAP_MONITOR_DELETE_SUCCESS:
      draft.sitemapMonitoringUpdate[action.sitemapPageId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.SITEMAP_MONITOR_DELETE_FAILURE:
      draft.sitemapMonitoringUpdate[action.sitemapPageId] = {
        status: FAILURE,
        errors: action?.payload?.errors || {},
        data: [],
      }
      break

    case REPORT.GET_REPORT:
      draft.report[action.siteId] = {
        ...sampleState.report,
        ...(draft.report[action.siteId] || {}),
      }
      break

    case REPORT.GET_REPORT_SUCCESS:
      draft.report[action.siteId] = {
        ...draft.report[action.siteId],
        status: SUCCESS,
        data: action.payload.data,
      }
      break
    case REPORT.GET_REPORT_FAILURE:
      draft.report[action.siteId] = {
        ...draft.report[action.siteId],
        status: FAILURE,
        message: action.payload.data || action.payload.message,
        data: [],
      }
      break
    // Page tracking
    case SITE.PAGE_TRACKING_LIST:
      draft.pageTracking[action.siteId] = {
        ...sampleState.pageTracking,
        ...(draft.pageTracking[action.siteId] || {}),
        status: REQUEST,
      }
      break
    case SITE.PAGE_TRACKING_LIST_SUCCESS:
      draft.pageTracking[action.siteId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.PAGE_TRACKING_LIST_FAILURE:
      draft.pageTracking[action.siteId] = {
        status: FAILURE,
        errors: action.payload,
        data: [],
      }
      break

    // Page tracking detail
    case SITE.PAGE_TRACKING_DETAIL:
      if (!currentDraft.pageTrackingDetail[action.siteId]) {
        draft.pageTrackingDetail[action.siteId] = {}
      }
      draft.pageTrackingDetail[action.siteId][action.pageTrackingId] = {
        ...sampleState.pageTrackingDetail,
        ...(draft.pageTrackingDetail[action.siteId][action.pageTrackingId] || {}),
        status: REQUEST,
      }

      break
    case SITE.PAGE_TRACKING_DETAIL_SUCCESS:
      draft.pageTrackingDetail[action.siteId][action.pageTrackingId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.PAGE_TRACKING_DETAIL_FAILURE:
      draft.pageTrackingDetail[action.siteId][action.pageTrackingId] = {
        status: FAILURE,
        errors: action.payload,
        data: {},
      }
      break

    // Page tracking report
    case SITE.PAGE_TRACKING_REPORT:
      if (!currentDraft.pageTrackingReport[action.siteId]) {
        draft.pageTrackingReport[action.siteId] = {}
      }
      draft.pageTrackingReport[action.siteId][action.pageTrackingId] = {
        ...sampleState.pageTrackingReport,
        ...(draft.pageTrackingReport[action.siteId][action.pageTrackingId] || {}),
        status: REQUEST,
      }
      break
    case SITE.PAGE_TRACKING_REPORT_SUCCESS:
      draft.pageTrackingReport[action.siteId][action.pageTrackingId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.PAGE_TRACKING_REPORT_FAILURE:
      draft.pageTrackingReport[action.siteId][action.pageTrackingId] = {
        status: FAILURE,
        errors: action.payload,
        data: {},
      }
      break

    // Page tracking report
    case SITE.PAGE_TRACKING_REPORT_HISTORY:
      if (!currentDraft.pageTrackingReportHistory[action.siteId]) {
        draft.pageTrackingReportHistory[action.siteId] = {}
      }
      draft.pageTrackingReportHistory[action.siteId][action.reportId] = {
        ...sampleState.pageTrackingReportHistory,
        ...(draft.pageTrackingReportHistory[action.siteId][action.reportId] || {}),
        status: REQUEST,
        data: [],
      }
      break
    case SITE.PAGE_TRACKING_REPORT_HISTORY_SUCCESS:
      draft.pageTrackingReportHistory[action.siteId][action.reportId] = {
        status: SUCCESS,
        errors: {},
        data: action.payload,
      }
      break
    case SITE.PAGE_TRACKING_REPORT_HISTORY_FAILURE:
      draft.pageTrackingReportHistory[action.siteId][action.reportId] = {
        status: FAILURE,
        errors: action.payload,
        data: [],
      }
      break

    // Create page tracking
    case SITE.PAGE_TRACKING_CREATE:
      draft.pageTrackingCreate[action.siteId] = {
        ...sampleState.pageTrackingCreate,
        ...(draft.pageTrackingCreate[action.siteId] || {}),
        status: REQUEST,
      }
      break
    case SITE.PAGE_TRACKING_CREATE_SUCCESS:
      draft.pageTrackingCreate[action.siteId] = {
        status: SUCCESS,
        errors: {},
      }
      break
    case SITE.PAGE_TRACKING_CREATE_FAILURE:
      draft.pageTrackingCreate[action.siteId] = {
        status: FAILURE,
        errors: action.payload,
      }
      break

    // page tracking listing history
    case SITE.PAGE_TRACKING_MORE_REPORT:
      if (!currentDraft.pageTrackingReportMore[action.siteId]) {
        draft.pageTrackingReportMore[action.siteId] = {}
      }
      draft.pageTrackingReportMore[action.siteId][action.pageTrackingId] = {
        ...(sampleState.pageTrackingReportMore[action.siteId]?.[action.pageTrackingId] || {}),
        status: REQUEST,
        data: [],
      }
      break
    case SITE.PAGE_TRACKING_MORE_REPORT_SUCCESS:
      draft.pageTrackingReportMore[action.siteId][action.pageTrackingId] = {
        status: SUCCESS,
        data: action.payload,
        errors: {},
      }
      break
    case SITE.PAGE_TRACKING_MORE_REPORT_FAILURE:
      draft.pageTrackingReportMore[action.siteId][action.pageTrackingId] = {
        status: FAILURE,
        errors: action.payload,
        data: [],
      }
      break

    // page tracking chart publisable
    case SITE.PAGE_TRACKING_CHART_PUBLISABLE:
      if (!currentDraft.pageTrackingChartPublisable[action.siteId]) {
        draft.pageTrackingChartPublisable[action.siteId] = {}
      }
      draft.pageTrackingChartPublisable[action.siteId] = {
        ...(sampleState.pageTrackingChartPublisable[action.siteId] || {}),
        status: REQUEST,
        data: [],
      }
      break

    case SITE.PAGE_TRACKING_CHART_PUBLISABLE_SUCCESS:
      draft.pageTrackingChartPublisable[action.siteId] = {
        status: SUCCESS,
        data: action.payload,
        errors: {},
      }
      break

    case SITE.PAGE_TRACKING_CHART_PUBLISABLE_FAILURE:
      draft.pageTrackingChartPublisable[action.siteId] = {
        status: FAILURE,
        errors: action.payload,
        data: [],
      }
      break

    // page tracking chart history
    case SITE.PAGE_TRACKING_CHART_HISTORY:
      if (!currentDraft.pageTrackingChartHistory[action.siteId]) {
        draft.pageTrackingChartHistory[action.siteId] = {}
      }
      draft.pageTrackingChartHistory[action.siteId] = {
        ...(sampleState.pageTrackingChartHistory[action.siteId] || {}),
        status: REQUEST,
        data: [],
      }
      break

    case SITE.PAGE_TRACKING_CHART_HISTORY_SUCCESS:
      draft.pageTrackingChartHistory[action.siteId] = {
        status: SUCCESS,
        data: action.payload,
        errors: {},
      }
      break

    case SITE.PAGE_TRACKING_CHART_HISTORY_FAILURE:
      draft.pageTrackingChartHistory[action.siteId] = {
        status: FAILURE,
        errors: action.payload,
        data: [],
      }
      break
  }
}, initialState)
