import { call, debounce, fork, put, select, takeLatest } from 'redux-saga/effects'

import { SITE } from '@constant/site'
import callApi from '@saga/api'
import {
  detailSitemapMonitor,
  getSitemapMonitor,
  getSitemapMonitorPage,
  getSitemapMonitorPageDetail,
  getlSitemapMonitorPageHistory,
  updateSitemapMonitor,
  deleteSitemapMonitor,
  createSitemapMonitor,
} from '@api/site'
import {
  createSitemapMonitorFailure,
  createSitemapMonitorSuccess,
  deleteSitemapMonitorFailure,
  deleteSitemapMonitorSuccess,
  getDetailSitemapMonitorFailure,
  getDetailSitemapMonitorSuccess,
  getPageDetailSitemapMonitorFailure,
  getPageDetailSitemapMonitorSuccess,
  getPageHistorySitemapMonitorFailure,
  getPageHistorySitemapMonitorSuccess,
  getPageSitemapMonitorFailure,
  getPageSitemapMonitorSuccess,
  getSitemapMonitorFailure,
  getSitemapMonitorSuccess,
  updateSitemapMonitorFailure,
  updateSitemapMonitorSuccess,
} from '@action/site'

function* getSitemapSaga({ siteId }) {
  const { ok, data: response } = yield call(callApi, getSitemapMonitor, siteId)
  if (ok) {
    yield put(getSitemapMonitorSuccess(siteId, response?.data))
  } else {
    yield put(getSitemapMonitorFailure(siteId, response?.data))
  }
}

function* getSitemapDetailSaga({ siteId, sitemapId }) {
  const { ok, data: response } = yield call(callApi, detailSitemapMonitor, siteId, sitemapId)
  if (ok) {
    yield put(getDetailSitemapMonitorSuccess(sitemapId, response?.data))
  } else {
    yield put(getDetailSitemapMonitorFailure(sitemapId, response?.data))
  }
}

function* getPageSitemapSaga({ siteId, sitemapId }) {
  const { ok, data: response } = yield call(callApi, getSitemapMonitorPage, siteId, sitemapId)
  if (ok) {
    yield put(getPageSitemapMonitorSuccess(sitemapId, response?.data))
  } else {
    yield put(getPageSitemapMonitorFailure(sitemapId, response?.data))
  }
}

function* getPageDetailSitemapSaga({ siteId, sitemapId, sitemapPageId }) {
  const { ok, data: response } = yield call(callApi, getSitemapMonitorPageDetail, siteId, sitemapId, sitemapPageId)
  if (ok) {
    yield put(getPageDetailSitemapMonitorSuccess(sitemapPageId, response?.data))
  } else {
    yield put(getPageDetailSitemapMonitorFailure(sitemapPageId, response?.data))
  }
}

function* getPageHistorySitemapSaga({ siteId, sitemapId, sitemapPageId }) {
  const { ok, data: response } = yield call(callApi, getlSitemapMonitorPageHistory, siteId, sitemapId, sitemapPageId)
  if (ok) {
    yield put(getPageHistorySitemapMonitorSuccess(sitemapPageId, response?.data))
  } else {
    yield put(getPageHistorySitemapMonitorFailure(sitemapPageId, response?.data))
  }
}

function* createSitemapSaga({ siteId, data, callback }) {
  const { ok, data: response } = yield call(callApi, createSitemapMonitor, siteId, data)
  if (ok) {
    yield put(createSitemapMonitorSuccess(siteId, response?.data))
    if (callback) {
      yield callback()
    }
    yield fork(getSitemapSaga, { siteId })
  } else {
    yield put(createSitemapMonitorFailure(siteId, response?.data))
  }
}

function* updateSitemapSaga({ siteId, sitemapId, data, callback }) {
  const { ok, data: response } = yield call(callApi, updateSitemapMonitor, siteId, sitemapId, data)
  if (ok) {
    yield put(updateSitemapMonitorSuccess(sitemapId, response?.data))
    if (callback) {
      yield callback()
    }
    yield fork(getSitemapSaga, { siteId })
  } else {
    yield put(updateSitemapMonitorFailure(sitemapId, response?.data))
  }
}

function* deleteSitemapSaga({ siteId, sitemapId, callback }) {
  const { ok, data: response } = yield call(callApi, deleteSitemapMonitor, siteId, sitemapId)
  if (ok) {
    yield put(deleteSitemapMonitorSuccess(sitemapId, response?.data))
    if (callback) {
      yield callback()
    }
    yield fork(getSitemapSaga, { siteId })
  } else {
    yield put(deleteSitemapMonitorFailure(sitemapId, response?.data))
  }
}

export default function* rootSaga() {
  yield debounce(100, SITE.SITEMAP_MONITOR_GET, getSitemapSaga)
  yield debounce(100, SITE.SITEMAP_MONITOR_DETAIL, getSitemapDetailSaga)
  yield debounce(100, SITE.SITEMAP_MONITOR_PAGE_GET, getPageSitemapSaga)
  yield debounce(100, SITE.SITEMAP_MONITOR_PAGE_DETAIL, getPageDetailSitemapSaga)
  yield debounce(100, SITE.SITEMAP_MONITOR_PAGE_HISTORY_GET, getPageHistorySitemapSaga)
  yield takeLatest(SITE.SITEMAP_MONITOR_CREATE, createSitemapSaga)
  yield takeLatest(SITE.SITEMAP_MONITOR_UPDATE, updateSitemapSaga)
  yield takeLatest(SITE.SITEMAP_MONITOR_DELETE, deleteSitemapSaga)
}
