import styled from 'styled-components'

export const Card = styled.div`
  border-radius: 12px;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.1);
`
export const CardBody = styled.div`
  padding: 24px;
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    i {
      font-size: 24px;
      color: ${props => props.theme.colors.dark};
    }
  }
`

export const CardHeader = styled.div`
  padding: 29px 30px 0 30px;
  display: flex;
  align-items: center;
`

export const CardTitle = styled.h5`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  line-height: 30px;
`
export const CardAction = styled.div`
  margin-left: auto;
`
