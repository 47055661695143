import moment from '@lib/moment'

export const DATE_FORMAT = 'YYYY-MM-DD'
export const FRONTEND_DATE_FORMAT = 'DD/MM/YYYY'

const dates = [
  {
    value: 'MOST_RECENT',
    label: `Most recent date ${moment().format('MMMM DD, YYYY')}`,
    date: () => ({
      startDate: moment().subtract(3, 'days').format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
    }),
  },
  {
    value: 'LAST_7_DAYS',
    label: 'Last 7 days',
    date: () => ({
      startDate: moment().subtract(7, 'days').format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
    }),
  },
  {
    value: 'LAST_28_DAYS',
    label: 'Last 28 days',
    date: () => ({
      startDate: moment().subtract(28, 'days').format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
    }),
  },
  {
    value: 'LAST_3_MONTHS',
    label: 'Last 3 months',
    date: () => ({
      startDate: moment().subtract(3, 'months').format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
    }),
  },
  {
    value: 'LAST_6_MONTHS',
    label: 'Last 6 months',
    date: () => ({
      startDate: moment().subtract(6, 'months').format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
    }),
  },
  {
    value: 'LAST_12_MONTHS',
    label: 'Last 12 months',
    date: () => ({
      startDate: moment().subtract(12, 'months').format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
    }),
  },
  {
    value: 'LAST_16_MONTHS',
    label: 'Last 16 months',
    date: () => ({
      startDate: moment().subtract(16, 'months').format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
    }),
  },
  {
    value: 'CUSTOM',
    label: 'Custom',
  },
]

export const compareDate = [
  {
    value: 'LAST_7_DAYS_TO_PREVIOUSS',
    label: 'Compare last 7 days to previous period',
    date: () => ({
      target: {
        startDate: moment().subtract(7, 'days').format(DATE_FORMAT),
        endDate: moment().format(DATE_FORMAT),
      },
      compare: {
        startDate: moment().subtract(14, 'days').format(DATE_FORMAT),
        endDate: moment().subtract(8, 'days').format(DATE_FORMAT),
      },
    }),
  },
  {
    value: 'LAST_28_DAYS_TO_PREVIOUS',
    label: 'Compare last 28 days to previous period',
    date: () => ({
      target: {
        startDate: moment().subtract(28, 'days').format(DATE_FORMAT),
        endDate: moment().format(DATE_FORMAT),
      },
      compare: {
        startDate: moment().subtract(56, 'days').format(DATE_FORMAT),
        endDate: moment().subtract(29, 'days').format(DATE_FORMAT),
      },
    }),
  },
  {
    value: 'LAST_3_MONTHS_TO_PREVIOUS',
    label: 'Compare last 3 months to previous period',
    date: () => ({
      target: {
        startDate: moment().subtract(3, 'months').format(DATE_FORMAT),
        endDate: moment().format(DATE_FORMAT),
      },
      compare: {
        startDate: moment().subtract(6, 'months').format(DATE_FORMAT),
        endDate: moment().subtract(3, 'months').format(DATE_FORMAT),
      },
    }),
  },
  {
    value: 'LAST_6_MONTHS_TO_PREVIOUS',
    label: 'Compare last 6 months to previous period',
    date: () => ({
      target: {
        startDate: moment().subtract(6, 'months').format(DATE_FORMAT),
        endDate: moment().format(DATE_FORMAT),
      },
      compare: {
        startDate: moment().subtract(12, 'months').format(DATE_FORMAT),
        endDate: moment().subtract(6, 'months').format(DATE_FORMAT),
      },
    }),
  },

  // over year
  {
    value: 'LAST_7_DAYS_OVER_YEAR',
    label: 'Compare last 7 days year over year',
    date: () => ({
      target: {
        startDate: moment().subtract(7, 'days').format(DATE_FORMAT),
        endDate: moment().format(DATE_FORMAT),
      },
      compare: {
        startDate: moment().subtract(1, 'year').subtract(7, 'days').format(DATE_FORMAT),
        endDate: moment().subtract(1, 'year').format(DATE_FORMAT),
      },
    }),
  },
  {
    value: 'LAST_28_DAYS_OVER_YEAR',
    label: 'Compare last 28 days year over year',
    date: () => ({
      target: {
        startDate: moment().subtract(28, 'days').format(DATE_FORMAT),
        endDate: moment().format(DATE_FORMAT),
      },
      compare: {
        startDate: moment().subtract(1, 'year').subtract(28, 'days').format(DATE_FORMAT),
        endDate: moment().subtract(1, 'year').format(DATE_FORMAT),
      },
    }),
  },
  {
    value: 'LAST_3_MONTHS_OVER_YEAR',
    label: 'Compare last 3 months year over year',
    date: () => ({
      target: {
        startDate: moment().subtract(3, 'months').format(DATE_FORMAT),
        endDate: moment().format(DATE_FORMAT),
      },
      compare: {
        startDate: moment().subtract(1, 'year').subtract(3, 'months').format(DATE_FORMAT),
        endDate: moment().subtract(1, 'year').format(DATE_FORMAT),
      },
    }),
  },
  {
    value: 'LAST_6_MONTHS_OVER_YEAR',
    label: 'Compare last 6 months year over year',
    date: () => ({
      target: {
        startDate: moment().subtract(6, 'months').format(DATE_FORMAT),
        endDate: moment().format(DATE_FORMAT),
      },
      compare: {
        startDate: moment().subtract(1, 'year').subtract(6, 'months').format(DATE_FORMAT),
        endDate: moment().subtract(1, 'year').format(DATE_FORMAT),
      },
    }),
  },
  {
    value: 'CUSTOM_COMPARE',
    label: 'Custom',
  },
]

export default dates
