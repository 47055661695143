import { REQUEST } from '@constant/reducer-status'
import moment from 'moment'
import Router from 'next/router'
import { createSelector } from 'reselect'

export const getMentionedPageSelector = siteId =>
  createSelector(
    state => state.site.misingKeywordPageData[siteId || Router.query.id],
    pageData => [pageData?.data || [], pageData?.status || REQUEST, pageData?.errors || {}]
  )

export const getPageQuerySelector = page =>
  createSelector(
    state => state.site.missingKeywordPage[page],
    pageData => [pageData?.data || [], pageData?.status || REQUEST, pageData?.errors || {}]
  )

export const getMissingKeywordQuerySelector = (page, query) =>
  createSelector(
    state => state.site.missingKeywordQuery[`${page}:${query}`],
    counter => [counter?.total != null ? counter?.total : null, counter?.timestamp ? moment(counter.timestamp) : null]
  )

export const getMentionedPageListSelector = siteId =>
  createSelector(
    state => state.site.missingKeywordPageList[siteId || Router.query.id],
    pageData => [pageData?.data || [], pageData?.status || REQUEST, pageData?.errors || {}]
  )
