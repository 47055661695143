export const SEOTEST = {
  LIST: 'SEO_TEST_LIST',
  LIST_SUCCESS: 'SEO_TEST_LIST_SUCCESS',
  LIST_FAILURE: 'SEO_TEST_LIST_FAILURE',

  CREATE: 'CREATE_TEST',
  CREATE_SUCCESS: 'CREATE_TEST_SUCCESS',
  CREATE_FAILURE: 'CREATE_TEST_FAILURE',

  UPDATE: 'UPDATE_TEST',
  UPDATE_SUCCESS: 'UPDATE_TEST_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_TEST_FAILURE',

  DETAIL_TEST: 'DETAIL_TEST',
  DETAIL_TEST_SUCCESS: 'DETAIL_SUCCESS',
  DETAIL_TEST_FAILURE: 'DETAIL_FAILURE',

  DETAIL_SUMMARY_TEST: 'DETAIL_SUMMARY_TEST',
  DETAIL_SUMMARY_TEST_SUCCESS: 'DETAIL_SUMMARY_TEST_SUCCESS',
  DETAIL_SUMMARY_TEST_FAILURE: 'DETAIL_SUMMARY_TEST_FAILURE',

  DETAIL_HISTORY_TEST: 'DETAIL_HISTORY_TEST',
  DETAIL_HISTORY_TEST_SUCCESS: 'DETAIL_HISTORY_TEST_SUCCESS',
  DETAIL_HISTORY_TEST_FAILURE: 'DETAIL_HISTORY_TEST_FAILURE',

  DISPATCH_TEST: 'DISPATCH_TEST',
  DISPATCH_TEST_SUCCESS: 'DISPATCH_TEST_SUCCESS',
  DISPATCH_TEST_FAILURE: 'DISPATCH_TEST_FAILURE',

  IMPORT_TEST: 'IMPORT_TEST',
  IMPORT_TEST_SUCCESS: 'IMPORT_TEST_SUCCESS',
  IMPORT_TEST_FAILURE: 'IMPORT_TEST_FAILURE',

  DELETE_TEST: 'DELETE_TEST',
  DELETE_TEST_SUCCESS: 'DELETE_TEST_SUCCESS',
  DELETE_TEST_FAILURE: 'DELETE_TEST_FAILURE',
}
