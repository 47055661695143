import styled from 'styled-components'

export const PopupModal = styled.div`
  position: fixed;
  top:  ${props=>!props.top ? 0 : props.top};
  left:${props=>!props.left ? 0 : props.left};
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: ${props=>!props.alignItems ? 'center' : props.alignItems};
  justify-content:  ${props=>!props.justifyContent ? 'center': props.alignItems};
`

export const ModalOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`

export const ModalParent = styled.div`
  position: relative;
  z-index: 9;
`
